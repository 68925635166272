
























































import Vue, { VueConstructor, VNode, VNodeData } from "vue";
import EnterScoresAction from "../../../MatchActions/EnterScoresAction.vue";
import {
  CompetitorScoringManager,
  EndScoringState,
} from "@/services/CompetitorScoringManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";
import ConfirmMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ConfirmMatchAction.vue";
import VNodeBridgeFunctional from "@/components/VNodeBridge/VNodeBridgeFunctional";
import ForfeitMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ForfeitMatchAction.vue";
import SetMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/SetMatchUnplayedAction.vue";
import { MatchActionVNode } from "@/controllers/app/marker-matches/scorer/types";
import { VDivider, VBtn } from "vuetify/lib";
import StandardResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/StandardResultScorerControlsMixin";
import EndScoringMixin from "@/controllers/app/marker-matches/scorer/Mixins/EndScoringMixin";
import CompetitorEndScorer from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/CompetitorEndScorer.vue";

/**
 * Internal component for managing controls for standard result that is used
 * by competitors.
 *
 * @internal
 */
export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof StandardResultScorerControlsMixin> &
    InstanceType<typeof EndScoringMixin>
>).extend({
  name: "CompetitorResultScorerControls",

  components: {
    CompetitorEndScorer,
    VNodeBridgeFunctional,
  },

  mixins: [StandardResultScorerControlsMixin, EndScoringMixin],

  data() {
    return {
      confirmingMatch: false,
      confirmingMatchError: null as string | null,
      completingMatch: false,
      completingMatchError: null as string | null,
      enableEndScoringControls: false,
    };
  },

  computed: {
    /**
     * Returns TRUE if we should display the actions; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayActions(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.matchActionNodeCount > 0;
    },

    /**
     * Returns the rendered match actions VNodes.
     *
     * @return {VNode[]}
     */
    renderedMatchActions(): VNode[] {
      return this._renderMatchActions();
    },

    /**
     * Returns the number of actual action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    matchActionNodeCount(): number {
      let count = 0;

      this.renderedMatchActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },

    /**
     * Returns TRUE if can enter ends; otherwise FALSE.
     *
     * @return {boolean}
     */
    canEnterEnds(): boolean {
      return (
        !this.isFinalized && this.matchData.matchCapabilities.canEnterEndScore
      );
    },
  },

  methods: {
    /**
     * Handle the "submit" event from the ConfirmMatchAction component.
     *
     * @param {Function} closeDialog close the dialog handler.
     */
    onConfirmMatch(closeDialog: () => void): void {
      if (this.confirmingMatch) {
        return;
      }

      this.confirmingMatch = true;
      this.confirmingMatchError = null;

      const manager = new CompetitorScoringManager();

      manager
        .confirmMatch(this.match.id)
        .then(() => {
          closeDialog();

          this.$emit("mark-match-completed");
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem confirming the match.";
          }

          this.confirmingMatchError = message;
        })
        .finally(() => {
          this.confirmingMatch = false;
        });
    },

    /**
     * Handles the "submit" event from the CompleteMatchAction component.
     *
     * @param {Function} closeDialog
     */
    onSubmitCompleteMatch(closeDialog: () => void) {
      if (this.completingMatch) {
        return;
      }

      this.completingMatch = true;
      this.completingMatchError = null;

      const manager = new CompetitorScoringManager();

      manager
        .completeMatch(this.match.id)
        .then(() => {
          closeDialog();

          this.$emit("mark-match-completed");
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was an error attempting to complete the match.";
          }

          this.completingMatchError = message;
        })
        .finally(() => {
          this.completingMatch = false;
        });
    },

    /**
     * Handle the "end-scores-completed" from the CompetitorEndScorer component.
     *
     * @param {EndScoringState} endScoringState
     */
    onEndScoresCompleted(endScoringState: EndScoringState): void {
      this.$emit("end-scores-completed", endScoringState);
    },

    /**
     * Handle the "ends-completed" event from the CompetitorEndScorer component.
     */
    onEndsCompleted(): void {
      this.$emit("ends-completed");
    },

    /**
     * Handle the "error" event from the CompetitorEndScorer component.
     *
     * @param {string} message
     */
    onError(message: string) {
      this.$emit("error", message);
    },

    /**
     * Returns the rendered match action components dynamically based
     * on the current competitor's match capabilities.
     *
     * @return {VNode[]}
     *
     * @private
     */
    _renderMatchActions(): VNode[] {
      const actionNodes: VNode[] = [];

      const divider = this.$createElement(VDivider);

      if (this.canEnterEnds) {
        actionNodes.push(
          this.createMatchActionVNode(
            VBtn,
            {
              props: {
                block: true,
                color: "primary",
                large: true,
                tile: true,
                text: true,
              },
              on: {
                click: () => {
                  this.enableEndScoringControls = true;
                },
              },
            },
            ["Enable Scoring"]
          ),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canFinalize) {
        let data: VNodeData = {
          props: {
            match: this.matchData,
            competitorOneTotalShots: this.competitorOneTotalShots,
            competitorTwoTotalShots: this.competitorTwoTotalShots,
            error: this.completingMatchError,
            loading: this.completingMatch,
          },
          on: {
            submit: this.onSubmitCompleteMatch,
            close: () => {
              // When the dialog is closed we should clear any existing errors.
              this.completingMatchError = null;
            },
          },
        };

        if (this.match.isCurrentUserAwayCompetitor) {
          data.scopedSlots = {
            warning: () => {
              return "";
            },
          };
        }

        actionNodes.push(
          this.createMatchActionVNode(CompleteMatchAction, data),
          divider
        );
      } else if (this.matchData.matchCapabilities.canConfirmResults) {
        actionNodes.push(
          this.createMatchActionVNode(ConfirmMatchAction, {
            props: {
              match: this.matchData,
              competitorOneTotalShots: this.competitorOneTotalShots,
              competitorTwoTotalShots: this.competitorTwoTotalShots,
              error: this.confirmingMatchError,
              loading: this.confirmingMatch,
            },
            on: {
              submit: this.onConfirmMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.confirmingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canEnterResults) {
        actionNodes.push(
          this.createMatchActionVNode(EnterScoresAction, {
            props: {
              competitorOne: this.matchData.competitorOne,
              competitorTwo: this.matchData.competitorTwo,
              title: "Enter Final Scores",
              buttonLabel: "Enter end-of-game Scores",
              error: this.enterFinalScoresError,
              loading: this.enteringFinalScores,
            },
            on: {
              submit: this.onEnterFinalScores,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.enterFinalScoresError = null;
              },
            },
            scopedSlots: {
              description: () => {
                return [
                  "Entering end-of-game-scores will ",
                  this.$createElement(
                    "strong",
                    {
                      class: "error--text",
                    },
                    "wipe all end results"
                  ),
                  " and select the winner based on the values entered below.",
                ];
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsUnplayed) {
        actionNodes.push(
          this.createMatchActionVNode(SetMatchUnplayedAction, {
            props: {
              match: this.matchData,
              error: this.markingMatchUnplayedError,
              loading: this.markingMatchUnplayed,
            },
            on: {
              submit: this.onMarkMatchUnplayed,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.markingMatchUnplayedError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsForfeit) {
        actionNodes.push(
          this.createMatchActionVNode(ForfeitMatchAction, {
            props: {
              match: this.matchData,
              error: this.forfeitingMatchError,
              loading: this.forfeitingMatch,
            },
            on: {
              submit: this.onForfeitMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.forfeitingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      return actionNodes;
    },
  },
});
