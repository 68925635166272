



































































import Vue, { VueConstructor } from "vue";
import { CompetitorModel } from "@memberpoint/ba-result-components";
import ControlActionDialogMixin from "@/controllers/app/marker-matches/scorer/Mixins/ControlActionDialogMixin";
import { SelectItem } from "@/types";
import { VForm } from "@/types/vuetify";
import MatchExtModel from "@/Classes/MatchExtModel";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ControlActionDialogMixin>
>).extend({
  name: "ForfeitMatchAction",

  mixins: [ControlActionDialogMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },
  },

  data() {
    return {
      forfeitTeamId: null as string | null,
    };
  },

  computed: {
    /**
     * Returns the team selection options.
     *
     * @return {SelectItem<string[]>}
     */
    teams(): SelectItem<string>[] {
      const competitorOne = this.match.competitorOne as CompetitorModel;
      const competitorTwo = this.match.competitorTwo as CompetitorModel;

      return [
        {
          text: competitorOne.getName(),
          value: competitorOne.id,
        },
        {
          text: competitorTwo.getName(),
          value: competitorTwo.id,
        },
      ];
    },
  },

  methods: {
    /**
     * Returns TRUE if the form passes validation; otherwise FALSE.
     *
     * @return {boolean}
     */
    validateForm(): boolean {
      if (!this.$refs.form) {
        return false;
      }

      const form = this.$refs.form as VForm;

      return form.validate();
    },

    /**
     * Handle the "submit" button event.
     */
    submit(): void {
      if (this.validateForm()) {
        this.$emit("submit", this.forfeitTeamId, this.closeDialog);
      }
    },
  },
});
