import Vue, { CreateElement } from "vue";
import { MatchActionVNode } from "@/controllers/app/marker-matches/scorer/types";

export default Vue.extend({
  methods: {
    /**
     * Returns the VNode that represents a component for the match action.
     *
     * @param args @see parameters of $createElement()
     *
     * @return {MatchActionVNode}
     */
    createMatchActionVNode(
      ...args: Parameters<CreateElement>
    ): MatchActionVNode {
      const node: MatchActionVNode = this.$createElement.apply(null, args);

      node._isMatchAction = true;

      return node;
    },
  },
});
