


















































































import Vue from "vue";
import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";

export default Vue.extend({
  name: "ConfirmMatchAction",

  extends: CompleteMatchAction,
});
