import Vue, { VueConstructor } from "vue";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import MatchExtModel from "@/Classes/MatchExtModel";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof MatchDetailsMixin>
>).extend({
  mixins: [MatchDetailsMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },
  },

  data() {
    return {
      matchData: this.match,
      markingMatchUnplayed: false,
      markingMatchUnplayedError: null as string | null,
      forfeitingMatch: false,
      forfeitingMatchError: null as string | null,
      completingMatch: false,
      completingMatchError: null as string | null,
      showManageMatchActions: false,
    };
  },

  computed: {
    /**
     * Returns TRUE if current user can finalize match; otherwise FALSE.
     *
     * @return {boolean}
     */
    canFinalize(): boolean {
      return this.match.matchCapabilities.canFinalize;
    },

    /**
     * Returns TRUE if we can call the "finalize" BYE action; otherwise FALSE.
     *
     * @return {boolean}
     */
    canFinalizeBye(): boolean {
      return this.canFinalize && this.isBye && !this.isFinalized;
    },
  },

  methods: {
    /**
     * Close the match action dialog and optionally reload the match.
     *
     * @param silent {boolean} If FALSE will emit a "close" event.
     */
    closeManageMatchActions(silent = false): void {
      this.showManageMatchActions = false;

      if (!silent) {
        this.$emit("close");
      }
    },

    /**
     * Handle the "submit" from the CompleteMatchAction component.
     *
     * @param {Function} closeDialog function to close the dialog.
     */
    onMarkMatchCompleted(closeDialog: () => void): void {
      if (this.completingMatch) {
        return;
      }

      this.completingMatch = true;
      this.completingMatchError = null;

      if (!this.matchData.matchCapabilities.canFinalize) {
        closeDialog();

        this.$emit("mark-match-completed");
      } else {
        const manager = new CompetitionScoringManager();

        manager
          .markMatchAsCompleted(this.match.id)
          .then(() => {
            closeDialog();
            this.$emit("mark-match-completed");
          })
          .catch((response) => {
            let message = ApiResponseHelper.getErrorMessageFromResponse(
              response
            );

            if (message === null) {
              message =
                "There was an error attempting to mark the match as completed.";
            }

            this.completingMatchError = message;
          })
          .finally(() => {
            this.completingMatch = false;
          });
      }
    },

    /**
     * Handles the "submit" event from the SetMatchUnplayedAction component.
     *
     * @param {string} reason
     * @param {Function} closeDialog
     */
    onMarkMatchUnplayed(reason: string, closeDialog: () => void): void {
      if (this.markingMatchUnplayed) {
        return;
      }

      this.markingMatchUnplayed = true;
      this.markingMatchUnplayedError = null;

      const manager = new CompetitionScoringManager();

      manager
        .markMatchAsUnPlayed(this.match.id, reason)
        .then(() => {
          closeDialog();

          this.closeManageMatchActions();
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message =
              "There was an error attempting to mark the match as unplayed.";
          }

          this.markingMatchUnplayedError = message;
        })
        .finally(() => {
          this.markingMatchUnplayed = false;
        });
    },

    /**
     * Handles the "submit" event from the ForfeitMatchAction component.
     *
     * @param {string} forfeitingCompetitorId
     * @param {Function} closeDialog
     */
    onForfeitMatch(
      forfeitingCompetitorId: string,
      closeDialog: () => void
    ): void {
      if (this.forfeitingMatch) {
        return;
      }

      this.forfeitingMatch = true;
      this.forfeitingMatchError = null;

      const manager = new CompetitionScoringManager();

      manager
        .markMatchAsForfeit(this.match.id, forfeitingCompetitorId)
        .then(() => {
          closeDialog();

          this.closeManageMatchActions();
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem forfeiting the match.";
          }

          this.forfeitingMatchError = message;
        })
        .finally(() => {
          this.forfeitingMatch = false;
        });
    },
  },
});
