





















































































import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import { CompetitorModel } from "@memberpoint/ba-result-components";
import Vue from "vue";

export default Vue.extend({
  name: "EndScorer",

  props: {
    endNumber: {
      type: [Number, String],
      required: true,
    },
    competitorOne: {
      type: CompetitorModel,
      required: true,
    },
    competitorTwo: {
      type: CompetitorModel,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    animateTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      compOneScore: 0,
      compTwoScore: 0,
      plus: mdiPlusCircleOutline,
      minus: mdiMinusCircleOutline,
    };
  },
  methods: {
    increaseCompetitorOneScore() {
      this.compTwoScore = 0;
      this.compOneScore++;
    },
    increaseCompetitorTwoScore() {
      this.compOneScore = 0;
      this.compTwoScore++;
    },
    decreaseCompetitorOneScore() {
      this.compTwoScore = 0;
      if (this.compOneScore > 0) {
        this.compOneScore--;
      }
    },
    decreaseCompetitorTwoScore() {
      this.compOneScore = 0;
      if (this.compTwoScore > 0) {
        this.compTwoScore--;
      }
    },
    clear() {
      this.compOneScore = 0;
      this.compTwoScore = 0;
    },
    onSubmit() {
      this.$emit("submit", {
        competitorOneScore: this.compOneScore,
        competitorTwoScore: this.compTwoScore,
      });
    },
  },
});
