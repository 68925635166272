




















































































































import Vue, { VNode, VueConstructor } from "vue";
import EndScoringMixin from "@/controllers/app/marker-matches/scorer/Mixins/EndScoringMixin";
import SetResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/SetResultScorerControlsMixin";
import EndScorer from "@/components/EndScorer/index.vue";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import { EndModel } from "@memberpoint/ba-result-components";
import { AxiosResponse } from "axios";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";
import SetMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/SetMatchUnplayedAction.vue";
import ForfeitMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ForfeitMatchAction.vue";
import { VBtn, VDivider } from "vuetify/lib";
import { MatchActionVNode } from "@/controllers/app/marker-matches/scorer/types";
import VNodeBridgeFunctional from "@/components/VNodeBridge/VNodeBridgeFunctional";
import FinalizeByeAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/FinalizeByeAction.vue";
import UndoLastEndDialog from "@/controllers/app/marker-matches/scorer/_internal/UndoLastEndDialog.vue";
import SetResultManager from "@/services/SetResultManager";
import MatchSet from "@/Classes/MatchSet";
import RemoveSetAction from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/Set/RemoveSetAction.vue";

/**
 * Internal component for managing controls for match set result that is used
 * by markers.
 *
 * @internal
 */
export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof SetResultScorerControlsMixin> &
    InstanceType<typeof EndScoringMixin>
>).extend({
  name: "MarkerSetResultScorerControls",

  components: {
    UndoLastEndDialog,
    FinalizeByeAction,
    VNodeBridgeFunctional,
    EndScorer,
  },

  mixins: [SetResultScorerControlsMixin, EndScoringMixin],

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableStartNextSet: {
      type: Boolean,
      required: false,
      default: false,
    },

    canRemoveSet: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      submittingEnd: false,
      completingMatch: false,
      completingMatchError: null as string | null,
      showMatchSetActions: false,
      startingNextSet: false,
      startingNextSetError: null as string | null,
    };
  },

  computed: {
    /**
     * Returns the number of ends that can be added.
     *
     * @return {?number}
     */
    numberOfEndsLeft(): number | null {
      if (typeof this.scoringOptions.endsPerMatch === "number") {
        return this.scoringOptions.endsPerMatch > this.ends.length
          ? this.scoringOptions.endsPerMatch - this.ends.length
          : 0;
      }

      return null;
    },

    /**
     * Returns TRUE if we can enter end scores; otherwise FALSE.
     *
     * @return {boolean}
     */
    canEnterEndScores(): boolean {
      return (
        this.match.matchCapabilities.canEnterEndScore &&
        this.matchSet !== null &&
        this.status === "pending" &&
        !this.isFinalized
      );
    },

    /**
     * Returns TRUE if we can display the action to undo the last end; otherwise FALSE.
     *
     * @return {boolean}
     */
    canUndoLastEnd(): boolean {
      return this.canEnterEndScores && this.hasEnds;
    },

    /**
     * Returns TRUE if we should display the actions to manage match; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayManageMatchActions(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.matchActionNodeCount > 0;
    },

    /**
     * Returns TRUE if we should display the actions for the match set; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayMatchSetActions(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.matchSetActionNodeCount > 0;
    },

    /**
     * Returns the VNodes for the main match actions.
     *
     * @return {VNode[]}
     */
    renderedMatchActions(): VNode[] {
      if (this.matchSet !== null) {
        return [];
      }

      return this._renderMatchActions();
    },

    /**
     * Returns the VNodes for the main match actions.
     *
     * @return {VNode[]}
     */
    renderedMatchSetActions(): VNode[] {
      return this._renderMatchSetActions();
    },

    /**
     * Returns the number of actual match action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    matchActionNodeCount(): number {
      let count = 0;

      this.renderedMatchActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },

    /**
     * Returns the number of actual match set action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    matchSetActionNodeCount(): number {
      let count = 0;

      this.renderedMatchSetActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },
  },

  methods: {
    /**
     * Close the match set actions dialog and optionally reload the match.
     *
     * @param silent {boolean} If FALSE will emit a "close" event.
     */
    closeMatchSetActions(silent = false): void {
      this.showMatchSetActions = false;

      if (!silent) {
        this.$emit("close");
      }
    },

    /**
     * Start the next set.
     *
     * @param {boolean} isTieBreak
     */
    startNextSet(isTieBreak = false) {
      if (this.startingNextSet) {
        return;
      }

      this.startingNextSet = true;
      this.startingNextSetError = null;

      const setManager = new SetResultManager();

      setManager
        .createNextSet(this.match.id, isTieBreak)
        .then((matchSet: MatchSet) => {
          this.$emit("next-match-set-created", matchSet);

          this.closeMatchSetActions(true);
        })
        .catch((response: AxiosResponse) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem starting next set.";
          }

          // Sending an "error" event with the error message to the parent
          // to handle.
          this.$emit("error", message);
        })
        .finally(() => {
          this.startingNextSet = false;
        });
    },

    onEndScoreSubmit(scores: {
      competitorOneScore: number;
      competitorTwoScore: number;
    }): void {
      if (this.submittingEnd) {
        return;
      }

      if (!this.matchSet) {
        return;
      }

      this.submittingEnd = true;

      const manager = new CompetitionScoringManager();

      manager
        .createEndForOwner(this.matchSet.handle, {
          endNumber: this.nextEndNumber,
          competitorOneScore: scores.competitorOneScore,
          competitorTwoScore: scores.competitorTwoScore,
        })
        .then((end: EndModel) => {
          // Add the new end to the end array so that the scorecard gets updated.
          this.ends.push(end);

          this.clearScorer();

          this.$emit("end-submitted", end, this.matchSet);
        })
        .catch((response: AxiosResponse) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem submitting the end.";
          }

          // Sending an "error" event with the error message to the parent
          // to handle.
          this.$emit("error", message);
        })
        .finally(() => {
          this.submittingEnd = false;
        });
    },

    /**
     * Handle the "submit" from the CompleteMatchAction component.
     *
     * @param {Function} closeDialog function to close the dialog.
     */
    onMarkMatchCompleted(closeDialog: () => void): void {
      if (this.completingMatch) {
        return;
      }

      this.completingMatch = true;
      this.completingMatchError = null;

      if (!this.match.matchCapabilities.canFinalize) {
        closeDialog();
        this.$emit("mark-match-completed");
      } else {
        const manager = new CompetitionScoringManager();

        manager
          .markMatchAsCompleted(this.match.id)
          .then(() => {
            closeDialog();

            this.$emit("mark-match-completed");
          })
          .catch((response) => {
            let message = ApiResponseHelper.getErrorMessageFromResponse(
              response
            );

            if (message === null) {
              message =
                "There was an error attempting to mark the match as completed.";
            }

            this.completingMatchError = message;
          })
          .finally(() => {
            this.completingMatch = false;
          });
      }
    },

    /**
     * Handle the "update" from the FinalizeByeAction component.
     */
    onFinalizeBye(): void {
      this.$emit("finalize-bye");
    },

    /**
     * Handle the "submit" event from the UndoLastEndDialog component.
     */
    onSubmitUndoLastEnd(): void {
      if (this.matchSet !== null) {
        this.$emit("undo-last-end-submitted", this.matchSet);
      }
    },

    /**
     * Render the actions for the match set.
     *
     * @return {VNode[]}
     *
     * @private
     */
    _renderMatchSetActions(): VNode[] {
      const actionNodes: VNode[] = [];

      const divider = this.$createElement(VDivider);

      if (this.canFinalize) {
        actionNodes.push(
          this.createMatchActionVNode(CompleteMatchAction, {
            props: {
              title: "Finalize Match",
              match: this.match,
              loading: this.completingMatch,
              error: this.completingMatchError,
            },
            on: {
              submit: this.onMarkMatchCompleted,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.completingMatchError = null;
              },
            },
            scopedSlots: {
              winnerDescription: () => {
                return this.renderSetsWinnerLoserDescription();
              },
            },
          }),
          divider
        );
      }

      if (!this.disableStartNextSet && this.canStartNextSet) {
        actionNodes.push(
          this.createMatchActionVNode(
            VBtn,
            {
              props: {
                block: true,
                large: true,
                tile: true,
                text: true,
                loading: this.startingNextSet,
              },
              on: {
                click: () => {
                  this.startNextSet();
                },
              },
            },
            ["START NEXT SET"]
          ),
          divider
        );
      }

      if (!this.disableStartNextSet && this.canHaveTieBreakSet) {
        actionNodes.push(
          this.createMatchActionVNode(
            VBtn,
            {
              props: {
                block: true,
                large: true,
                tile: true,
                text: true,
                loading: this.startingNextSet,
              },
              on: {
                click: () => {
                  this.startNextSet(true);
                },
              },
            },
            ["START NEXT SET AS TIEBREAK"]
          ),
          divider
        );
      }

      if (this.canRemoveSet && this.matchSet) {
        actionNodes.push(
          this.createMatchActionVNode(RemoveSetAction, {
            props: {
              match: this.matchData,
              setNumber: this.matchSet.setNumber,
            },
            on: {
              "set-removed": (matchSet: MatchSet) => {
                this.$emit("set-removed", matchSet);

                this.closeMatchSetActions(true);
              },
            },
          })
        );
      }

      return actionNodes;
    },

    /**
     * Render the actions for the main match.
     *
     * @return {VNode[]}
     *
     * @private
     */
    _renderMatchActions(): VNode[] {
      const actionNodes: VNode[] = [];

      const divider = this.$createElement(VDivider);

      if (this.canFinalize) {
        actionNodes.push(
          this.createMatchActionVNode(CompleteMatchAction, {
            props: {
              title: "Finalize Match",
              match: this.match,
              loading: this.completingMatch,
              error: this.completingMatchError,
            },
            on: {
              submit: this.onMarkMatchCompleted,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.completingMatchError = null;
              },
            },
            scopedSlots: {
              winnerDescription: () => {
                return this.renderSetsWinnerLoserDescription();
              },
            },
          }),
          divider
        );
      }

      if (this.match.matchCapabilities.canSetAsUnplayed) {
        actionNodes.push(
          this.createMatchActionVNode(SetMatchUnplayedAction, {
            props: {
              loading: this.markingMatchUnplayed,
              error: this.markingMatchUnplayedError,
            },
            on: {
              submit: this.onMarkMatchUnplayed,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.markingMatchUnplayedError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.match.matchCapabilities.canSetAsForfeit) {
        actionNodes.push(
          this.createMatchActionVNode(ForfeitMatchAction, {
            props: {
              match: this.match,
              loading: this.forfeitingMatch,
              error: this.forfeitingMatchError,
            },
            on: {
              submit: this.onForfeitMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.forfeitingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      return actionNodes;
    },
  },
});
