import Vue, { PropOptions, PropType } from "vue";
import { EndModel } from "@memberpoint/ba-result-components";
import EndScorer from "@/components/EndScorer/index.vue";

export default Vue.extend({
  props: {
    ends: {
      type: Array as PropType<EndModel[]>,
      required: false,
      default() {
        return [];
      },
    } as PropOptions<EndModel[]>,
  },

  computed: {
    /**
     * Returns the total end shots for competitor 1.
     *
     * @return {number}
     */
    competitorOneTotalEndShots(): number {
      return this.ends.reduce((acc, end: EndModel) => {
        acc += end.getCompetitorOneShots();

        return acc;
      }, 0);
    },

    /**
     * Returns the total end shots for competitor 2.
     *
     * @return {number}
     */
    competitorTwoTotalEndShots(): number {
      return this.ends.reduce((acc, end: EndModel) => {
        acc += end.getCompetitorTwoShots();

        return acc;
      }, 0);
    },

    /**
     * Returns the next end number.
     *
     * @return {number}
     */
    nextEndNumber(): number {
      if (this.ends.length < 1) {
        return 1;
      }

      const highestEnd = this.ends.reduce((prev, current) => {
        return prev.getEndNumber() > current.getEndNumber() ? prev : current;
      });

      return highestEnd.getEndNumber() + 1;
    },

    /**
     * Returns TRUE if the match has ends; otherwise FALSE.
     *
     * @return {boolean}
     */
    hasEnds(): boolean {
      return this.ends.length > 0;
    },
  },

  methods: {
    /**
     * Clear the score inputs from the EndScorer component if it is referenced.
     */
    clearScorer() {
      if (this.$refs.scorer && typeof this.$refs.scorer === "object") {
        const scorer = this.$refs.scorer as InstanceType<typeof EndScorer>;

        typeof scorer.clear === "function" && scorer.clear();
      }
    },
  },
});
