import Configuration from "@/lib/Configuration";
import http from "@/lib/AppHttpResource";

export default class StandardResultManager {
  /**
   * Enter the final scores for this match.
   *
   * @param {string} matchID
   * @param {number} competitorOneScore
   * @param {number} competitorTwoScore
   * @param {boolean} finalize
   */
  enterFinalScores(
    matchID: string,
    competitorOneScore: number,
    competitorTwoScore: number,
    finalize = false
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/final-scores`;

      http
        .put(url, {
          attributes: { competitorOneScore, competitorTwoScore, finalize },
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
