

























































import Vue, { VueConstructor } from "vue";
import ControlActionDialogMixin from "@/controllers/app/marker-matches/scorer/Mixins/ControlActionDialogMixin";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ControlActionDialogMixin>
>).extend({
  name: "MarkSideMatchUnplayedAction",

  mixins: [ControlActionDialogMixin],
});
