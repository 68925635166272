<template>
  <v-container fluid class="main-skeleton">
    <v-row class="header mb-5">
      <match-header-skeleton-loader />
    </v-row>
    <v-row class="score-content" justify="center">
      <v-col cols="9" md="6">
        <v-skeleton-loader type="text@10" class="lg" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MatchHeaderSkeletonLoader from "@/controllers/app/marker-matches/scorer/_internal/MatchHeaderSkeletonLoader";
export default {
  name: "ScorerSkeletonLoader",
  components: { MatchHeaderSkeletonLoader },
  computed: {
    /**
     * Returns TRUE if currently in mobile view; otherwise FALSE.
     *
     * @return {boolean}
     */
    isMobile() {
      return this.$vuetify.breakpoint.width < 701;
    },
  },
};
</script>

<style scoped lang="scss">
.main-skeleton {
  .v-skeleton-loader.lg {
    ::v-deep .v-skeleton-loader__text {
      height: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>
