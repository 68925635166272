import Configuration from "@/lib/Configuration";
import http from "@/lib/AppHttpResource";
import MatchCapabilities from "@/Classes/MatchCapabilities";
import { LooseObject } from "@/types";

export interface EndScoringState {
  atEndNumber: number;
  hasEnd: boolean;
  hasEndProxy: boolean;
  endProxyEnteredByCompetitor: string | null;
  endProxyCompleted: boolean;
  completeEndsAction: null | {
    isConfirmed: boolean;
    initiatedByCompetitor: string;
  };
  canScoreEnds: boolean;
}

export interface CompetitorDetailsPayload {
  competitorId: string;
  isCompetitor1: boolean;
  isCompetitor2: boolean;
  competitorName?: string;
  matchCapabilities: MatchCapabilities;
}

export interface CompleteEndsPayload {
  isConfirmed: boolean;
  competitor1: string;
  competitor2: string;
  initiatedByCompetitor: string;
}

export class CompetitorScoringManager {
  /**
   * Get the end scoring state at given end number.
   *
   * @param {number} endNumber
   * @param {string} ownerHandle
   * @return Promise<EndScoringState>
   */
  getEndScoringStateAt(
    endNumber: number,
    ownerHandle: string
  ): Promise<EndScoringState> {
    return new Promise<EndScoringState>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/competitors/${ownerHandle}/ends/${endNumber}`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(data[0].attributes as EndScoringState);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the current user's competitor details for the match.
   *
   * @param {string} matchId
   * @return {Promise<CompetitorDetailsPayload>}
   */
  getCurrentCompetitorDetailsForMatch(
    matchId: string
  ): Promise<CompetitorDetailsPayload> {
    return new Promise<CompetitorDetailsPayload>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/matches/${matchId}/competitors/details`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          const attributes = data[0].attributes as LooseObject;
          const capabilities = data[0].capabilities || ({} as LooseObject);

          const payload: CompetitorDetailsPayload = {
            competitorId: attributes.competitorId as string,
            isCompetitor1: attributes.isCompetitor1 as boolean,
            isCompetitor2: attributes.isCompetitor2 as boolean,
            matchCapabilities: new MatchCapabilities(capabilities),
          };

          if (typeof attributes.competitorName === "string") {
            payload.competitorName = attributes.competitorName;
          }

          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Enter the end scores as competitor.
   *
   * @param {string} ownerHandle
   * @param {Record} attributes
   * @return {Promise<EndScoringState>}
   */
  enterEndScores(
    ownerHandle: string,
    attributes: {
      endNumber: number;
      competitorOneScore: number;
      competitorTwoScore: number;
    }
  ): Promise<EndScoringState> {
    return new Promise<EndScoringState>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/competitors/${ownerHandle}/ends`;

      http
        .post(url, { attributes })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(data[0].attributes as EndScoringState);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Remove the entered end scores at given end number by the current competitor.
   *
   * @param {string} ownerHandle
   * @param {number} endNumber
   */
  removeEndScores(
    ownerHandle: string,
    endNumber: number
  ): Promise<EndScoringState> {
    return new Promise<EndScoringState>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/competitors/${ownerHandle}/ends/${endNumber}`;

      http
        .delete(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(data[0].attributes as EndScoringState);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Confirm the match by current competitor.
   *
   * @param {string} matchId
   */
  confirmMatch(matchId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/matches/${matchId}/confirm`;

      http
        .put(url)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Complete the match.
   *
   * @param {string} matchId
   * @return {Promise<void>}
   */
  completeMatch(matchId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/matches/${matchId}/complete`;

      http
        .put(url)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Complete ends.
   *
   * @param {string} ownerHandle
   * @return {Promise<CompleteEndsPayload>}
   */
  completeEnds(ownerHandle: string): Promise<CompleteEndsPayload> {
    return new Promise<CompleteEndsPayload>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/competitors/${ownerHandle}/complete-ends`;

      http
        .post(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(data[0].attributes as CompleteEndsPayload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Removes the request for completing ends initiated by the current user.
   * This method will return a promise that resolves to TRUE
   * on success or FALSE If there is no request made by the current user.
   *
   * @param {string} ownerHandle
   */
  removeCompleteEndsRequest(ownerHandle: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competitor-scoring/competitors/${ownerHandle}/complete-ends`;

      http
        .delete(url)
        .then((response) => {
          const data = response.data.data || [];

          let success = false;

          if (
            data.length === 1 &&
            data[0].attributes &&
            typeof data[0].attributes === "object" &&
            typeof data[0].attributes.success === "boolean"
          ) {
            success = data[0].attributes.success;
          }

          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
