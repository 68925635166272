import Vue from "vue";
import {
  EndScoringState,
  CompetitorScoringManager,
} from "@/services/CompetitorScoringManager";

const POLLING_INTERVAL_MS = 5000;

export default Vue.extend({
  data() {
    return {
      pollingEnd: null as number | null,
      pollingTimeout: null as number | null,
      stoppingPolling: false,
    };
  },

  methods: {
    /**
     * Start polling for the end scoring state and given end number.
     *
     * @param {number} endNumber
     * @param {string} ownerHandle
     * @param {Function|null} callback
     */
    startPollingEndScoringState(
      endNumber: number,
      ownerHandle: string,
      callback: ((endScoringState: EndScoringState) => void) | null = null
    ) {
      // Stop the current polling if there is one.
      if (typeof this.pollingTimeout === "number") {
        this.stopPollingEndScoringState();
      }

      this.pollingEnd = endNumber;
      this.stoppingPolling = false;

      const timeoutHandler = () => {
        this.getEndScoringState(endNumber, ownerHandle).then(
          (endScoringState: EndScoringState) => {
            // If a callback is provided then call it and provide the fetched end scoring state.
            if (typeof callback === "function") {
              callback(endScoringState);
            }

            // Handle the case when we are trying to stop the polling
            // but this point has gone through.
            if (!this.stoppingPolling) {
              this.pollingTimeout = setTimeout(
                timeoutHandler,
                POLLING_INTERVAL_MS
              );
            }
          }
        );
      };

      timeoutHandler();
    },

    /**
     * Get the end scoring state for the end number.
     *
     * @param endNumber
     * @param ownerHandle
     */
    getEndScoringState(endNumber: number, ownerHandle: string) {
      return this.getCompetitorScoringManager()
        .getEndScoringStateAt(endNumber, ownerHandle)
        .then((endScoringState) => {
          return Promise.resolve(endScoringState);
        });
    },

    /**
     * Stop the current polling if end scoring state.
     */
    stopPollingEndScoringState(): void {
      this.pollingEnd = null;
      this.stoppingPolling = true;

      if (typeof this.pollingTimeout === "number") {
        clearTimeout(this.pollingTimeout);
      }
    },

    /**
     * Returns an instance of the CompetitorScoringManager.
     *
     * @return {CompetitorScoringManager}
     */
    getCompetitorScoringManager(): CompetitorScoringManager {
      return new CompetitorScoringManager();
    },
  },
});
