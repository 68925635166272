




































































import Vue from "vue";
import ForfeitMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ForfeitMatchAction.vue";

export default Vue.extend({
  name: "ForfeitSideMatchAction",

  extends: ForfeitMatchAction,
});
