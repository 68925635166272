










































































































































































































































































import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import {
  CompetitorPlayerModel,
  EndModel,
} from "@memberpoint/ba-result-components";
import { ScoringOptions, SelectItem } from "@/types";
import SideMatch from "@/Classes/SideMatch";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import SideMatchStatusAlert from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchStatusAlert.vue";
import MatchStateAlert from "@/components/MatchStateAlert/index.vue";
import MatchOverview from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/MatchOverview.vue";
import {
  mdiChevronDown,
  mdiClose,
  mdiAccountGroupOutline,
  mdiCheckCircle,
} from "@mdi/js";
import CompetitorScorePollingMixin from "@/controllers/app/marker-matches/scorer/Mixins/CompetitorScorePollingMixin";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import CompetitorSideShotResultScorerControls from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/ResultScorerControls/CompetitorSideShotResultScorerControls.vue";
import MarkerSideShotResultScorerControls from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/ResultScorerControls/MarkerSideShotResultScorerControls.vue";
import MatchExtModel from "@/Classes/MatchExtModel";
import BottomSheetSelection from "@/components/BottomSheetSelection/index.vue";
import MatchBlock from "@/components/MatchBlock/index.vue";
import LoadSideMatchesMixin from "@/controllers/app/marker-matches/scorer/Mixins/LoadSideMatchesMixin";
import Team from "@/Classes/Team";

const MANAGE_MATCH_OPTION = "--MANAGE_MATCH_OPTION--";

interface ManageMatchOption extends SelectItem<string> {
  isSideMatch: boolean;
  isFinalized?: boolean;
  competitor1?: string;
  competitor2?: string;
}

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchDetailsMixin> &
    InstanceType<typeof CompetitorScorePollingMixin> &
    InstanceType<typeof LoadSideMatchesMixin>
>).extend({
  name: "SideShotResultScorer",

  components: {
    MatchBlock,
    BottomSheetSelection,
    MarkerSideShotResultScorerControls,
    CompetitorSideShotResultScorerControls,
    MatchOverview,
    MatchStateAlert,
    SideMatchStatusAlert,
  },

  mixins: [
    MatchDetailsMixin,
    CompetitorScorePollingMixin,
    LoadSideMatchesMixin,
  ],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,

    sideMatchId: {
      type: String,
      required: false,
      default: null,
    },

    enableCompetitorEndScoring: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      sideMatches: [] as Array<SideMatch>,
      selected: (this.sideMatchId || MANAGE_MATCH_OPTION) as string | null,
      matchData: this.match,
      loadingEnds: false,
      loadingEndsErrors: null as string | null,
      ends: [] as Array<EndModel>,
      errorMessage: null as string | null,
      showErrorDialog: false,
      closeIcon: mdiClose,
      dropdownIcon: mdiChevronDown,
      groupIcon: mdiAccountGroupOutline,
      checkIcon: mdiCheckCircle,
      enableSideMatchSelection: !this.sideMatchId,
      competitorEndScoringEnabled: this.enableCompetitorEndScoring,
    };
  },

  computed: {
    /**
     * Returns the total shots for competitor 1 from all side matches.
     *
     * @return {number}
     */
    competitor1TotalShotsFromSideMatches(): number {
      return this.sideMatches.reduce((acc, sideMatch) => {
        acc += sideMatch.competitorOneShots;

        return acc;
      }, 0);
    },

    /**
     * Returns the total shots for competitor 2 from all side matches.
     *
     * @return {number}
     */
    competitor2TotalShotsFromSideMatches(): number {
      return this.sideMatches.reduce((acc, sideMatch) => {
        acc += sideMatch.competitorTwoShots;

        return acc;
      }, 0);
    },

    /**
     * Returns the options for managing match selection.
     *
     * @return {ManageMatchOption[]}
     */
    manageMatchOptions(): ManageMatchOption[] {
      const options = [
        {
          text: "View Match Overview",
          value: MANAGE_MATCH_OPTION,
          isSideMatch: false,
        },
      ];

      this.sideMatches.forEach((sideMatch: SideMatch, index: number) => {
        options.push({
          text: `Rink ${index + 1} - ${this.getLabelForSideMatch(sideMatch)}`,
          value: sideMatch.id,
          isFinalized: sideMatch.isFinalized,
          isSideMatch: true,
        } as ManageMatchOption);
      });

      return options;
    },

    /**
     * @return {boolean}
     */
    manageMatchOptionSelected(): boolean {
      return this.selected === MANAGE_MATCH_OPTION;
    },

    /**
     * Returns the ID of the side match that is currently selected.
     *
     * @return {string|null}
     */
    selectedSideMatchID(): string | null {
      if (
        typeof this.selected === "string" &&
        this.selected !== MANAGE_MATCH_OPTION
      ) {
        return this.selected;
      }

      return null;
    },

    /**
     * Returns the current selected side match.
     *
     * @return {SideMatch|null}
     */
    selectedSideMatch(): SideMatch | null {
      if (this.selectedSideMatchID === null) {
        return null;
      }

      return this.getSideMatchByID(this.selectedSideMatchID);
    },

    /**
     * Returns the players for competitor 1 from the selected side match.
     *
     * @return {CompetitorPlayerModel[]}
     */
    selectedSideMatchCompetitor1Players(): CompetitorPlayerModel[] {
      if (this.selectedSideMatch === null || !this.selectedSideMatch.teamOne) {
        return [];
      }

      return this._createPlayersFromTeam(this.selectedSideMatch.teamOne);
    },

    /**
     * Returns the players for competitor 2 from the selected side match.
     *
     * @return {CompetitorPlayerModel[]}
     */
    selectedSideMatchCompetitor2Players(): CompetitorPlayerModel[] {
      if (this.selectedSideMatch === null || !this.selectedSideMatch.teamTwo) {
        return [];
      }

      return this._createPlayersFromTeam(this.selectedSideMatch.teamTwo);
    },

    /**
     * Returns TRUE if we should display a warning message that
     * the match can not be confirmed/finalized by the current user
     * as home team.
     *
     * @return {boolean}
     */
    canDisplayUnableToConfirmMatchMessageByHomeCompetitor(): boolean {
      if (!this.match.isCurrentUserHomeCompetitor) {
        return false;
      }

      if (this.match.matchCapabilities.canConfirmResults) {
        return false;
      }

      return this.canDisplayUnableToFinalizeMatchWarningMessage;
    },

    /**
     * Returns TRUE if we should display a warning message that
     * the match can not be finalized by the current user
     * as away team.
     *
     * @return {boolean}
     */
    canDisplayUnableToFinalizeMatchMessageByAwayCompetitor(): boolean {
      if (!this.match.isCurrentUserAwayCompetitor) {
        return false;
      }

      return this.canDisplayUnableToFinalizeMatchWarningMessage;
    },

    /**
     * Returns TRUE if we should display a warning message
     * that the match can not be confirmed/finalized by the current
     * competitor even though all side matches have been finalized.
     *
     * @return {boolean}
     */
    canDisplayUnableToFinalizeMatchWarningMessage(): boolean {
      if (
        this.isCompetitorContext &&
        !this.match.matchCapabilities.canFinalize &&
        !this.isFinalized &&
        this.sideMatches.length > 0
      ) {
        let sideMatchesWithoutTeamPlayersCounter = 0;

        for (let i = 0; i < this.sideMatches.length; i++) {
          // Only display the warning if ALL side matches have been finalized.
          if (!this.sideMatches[i].isFinalized) {
            return false;
          }

          const hasTeam1Players =
            this.sideMatches[i].teamOne &&
            (this.sideMatches[i].teamOne as Team).teamPlayers.length > 0;

          const hasTeam2Players =
            this.sideMatches[i].teamTwo &&
            (this.sideMatches[i].teamTwo as Team).teamPlayers.length > 0;

          // If the side match does not have players assigned to both side
          // then we should display the warning message.
          if (!hasTeam1Players || !hasTeam2Players) {
            sideMatchesWithoutTeamPlayersCounter++;
          }
        }

        if (sideMatchesWithoutTeamPlayersCounter > 0) {
          return true;
        }
      }

      return false;
    },
  },

  watch: {
    /**
     * Watch for changes in side match selection.
     *
     * @param {SideMatch|null} newValue
     */
    selectedSideMatch(newValue: SideMatch | null) {
      if (newValue !== null) {
        if (this.scoringOptions.hasEndScoring) {
          this.loadEndsForSideMatch(newValue);
        }
      } else {
        this.ends = [];
      }
    },
  },

  created() {
    this.loadSideMatches();
  },

  methods: {
    /**
     * Returns the label for the side match.
     *
     * @return {string}
     */
    getLabelForSideMatch(sideMatch: SideMatch): string {
      let text = sideMatch.format.name;

      if (sideMatch.format.specialisation) {
        text += ` (${sideMatch.format.specialisation})`;
      }

      return text;
    },

    /**
     * Returns the side match matching the given ID or NULL if there is none selected.
     *
     * @return {SideMatch|null}
     */
    getSideMatchByID(id: string): SideMatch | null {
      const index = this.sideMatches.findIndex(
        (sideMatch) => sideMatch.id === id
      );

      if (index === -1) {
        return null;
      }

      return this.sideMatches[index] || null;
    },

    /**
     * Load the side matches.
     */
    loadSideMatches(): void {
      if (this.isBye) {
        return;
      }

      this.loadSideMatchesForMatch(this.match).then((sideMatches) => {
        this.sideMatches = sideMatches;

        // If a side-match ID is provided make sure it matches one in
        // the array of side-matches loaded. If it is not then we default the
        // selection to the main match.
        if (this.sideMatchId) {
          const index = this.sideMatches.findIndex(
            (sideMatch) => sideMatch.id === this.sideMatchId
          );

          if (index === -1) {
            this.selected = MANAGE_MATCH_OPTION;
            this.enableSideMatchSelection = true;
          }
        }
      });
    },

    /**
     * Load the ends for the side match.
     *
     * @param {SideMatch} sideMatch
     */
    loadEndsForSideMatch(sideMatch: SideMatch): void {
      this.loadingEnds = true;
      this.loadingEndsErrors = null;

      const markerManager = new CompetitionScoringManager();

      markerManager
        .getEndsForOwner(sideMatch.handle)
        .then((ends: Array<EndModel>) => {
          this.ends = ends;
        })
        .catch((response) => {
          this.loadingEndsErrors = ApiResponseHelper.getErrorMessageFromResponse(
            response
          );
        })
        .finally(() => {
          this.loadingEnds = false;
        });
    },

    /**
     * Returns the next side match ID from the currently selected side match.
     *
     * @return {string|null}
     */
    getNextSideMatchId(): string | null {
      for (let i = 0; i < this.manageMatchOptions.length; ++i) {
        const option = this.manageMatchOptions[i];

        // We want only the next side match option that is not finalized.
        if (
          option.value !== this.selected &&
          option.isSideMatch &&
          !option.isFinalized
        ) {
          return option.value;
        }
      }

      return null;
    },

    /**
     * Handle the "undo-last-end-submitted" event from the MarkerResultScorerControls component.
     *
     * @param {SideMatch} sideMatch
     */
    onSubmitUndoLastEnd(sideMatch: SideMatch): void {
      // Reload the ends for the side match.
      this.loadEndsForSideMatch(sideMatch);
    },

    /**
     * Handle the "mark-match-completed" event from the MarkerResultScorerControl component.
     */
    onMatchCompleted(): void {
      this.$emit("completed");
    },

    onClosePreselectedSideMatch(): void {
      this.enableSideMatchSelection = true;
    },

    /**
     * Handle the "end-scores-completed" event from the CompetitorSideShotResultScorerControls component.
     */
    onEndScoresCompleted(): void {
      if (this.selectedSideMatch) {
        this.loadEndsForSideMatch(this.selectedSideMatch);
      }
    },

    /**
     * Handle the "ends-completed' event from the CompetitorSideShotResultsScorerControls component.
     */
    onEndsCompleted(): void {
      // Emit an "updated" event so the parent can reload if needed.
      this.$emit("updated", null, this.competitorEndScoringEnabled);
    },

    /**
     * Handle the "end-scoring-enabled' event from the CompetitorSideShotResultsScorerControls component.
     */
    onEndScoringEnabled(): void {
      this.competitorEndScoringEnabled = true;
    },

    /**
     * Handle the "finalize-bye" event from the MarkerResultScorerControls component.
     */
    onFinalizeBye(): void {
      this.$emit("updated");
    },

    /**
     * Handle the "close" event from the MarkerResultScorerControl component.
     */
    onCloseControls(): void {
      // Emit an "updated" event so the parent can reload if needed.
      this.$emit("updated", null, this.competitorEndScoringEnabled);
    },

    /**
     * Handle "error" event from any child components and display the error message.
     *
     * @param {string} message
     */
    onError(message: string): void {
      this.errorMessage = message;
      this.showErrorDialog = true;
    },
  },
});
