

































































































import TitleBar from "@/components/TitleBar/index.vue";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import { CompetitionModel } from "@memberpoint/ba-result-components";
import { mdiArrowLeft } from "@mdi/js";
import ScorerSkeletonLoader from "@/controllers/app/marker-matches/scorer/_internal/ScorerSkeletonLoader.vue";
import PullToRefresh from "@/directives/PullToRefresh";
import ScoredMatchesMixin from "@/Mixins/ScoredMatchesMixin";
import Vue, { VueConstructor } from "vue";
import StandardResultScorer from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/Standard/StandardResultScorer.vue";
import { LooseObject, ScoringOptions } from "@/types";
import SideShotResultScorer from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideShotResultScorer.vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import MatchExtModel from "@/Classes/MatchExtModel";
import SetResultScorer from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/Set/SetResultScorer.vue";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof ScoredMatchesMixin> &
    InstanceType<typeof MatchDetailsMixin>
>).extend({
  name: "AppMarkerMatchesScorerController",

  components: {
    SetResultScorer,
    SideShotResultScorer,
    StandardResultScorer,
    ScorerSkeletonLoader,
    TitleBar,
  },

  directives: {
    pullToRefresh: PullToRefresh,
  },

  mixins: [ScoredMatchesMixin, MatchDetailsMixin],

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      loadingMatchError: null as string | null,
      scoringOptions: {} as ScoringOptions,
      backArrow: mdiArrowLeft,
      matchData: null as MatchExtModel | null,
      queryParams: {} as LooseObject,
    };
  },

  computed: {
    /**
     * Returns the result type of the competition.
     *
     * @return {string|null}
     */
    resultType(): string | null {
      if (
        this.matchData === null ||
        !(this.matchData.competition instanceof CompetitionModel)
      ) {
        return null;
      }

      return (this.matchData.competition as CompetitionModel).resultType;
    },

    /**
     * Returns the v-bind props for the scorer component.
     *
     * @return {LooseObject}
     */
    scorerComponentProps(): LooseObject {
      const props = Object.assign({}, this.queryParams);

      props.match = this.matchData;
      props.scoringOptions = this.scoringOptions;

      return props;
    },
  },

  watch: {
    id: {
      immediate: true,
      handler() {
        this.loadMatch();
      },
    },
  },

  created() {
    if (typeof this.$route.query.p === "string") {
      try {
        this.queryParams = JSON.parse(this.$route.query.p);
      } catch (error) {
        // Ignore
      }
    }
  },

  methods: {
    /**
     * Load the match details.
     */
    loadMatch(): void {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.scoringOptions = {} as ScoringOptions;
      this.loadingMatchError = null;

      const manager = new CompetitionScoringManager();

      manager
        .getMatchForMarker(this.id)
        .then((payload) => {
          this.$set(this, "matchData", payload.match);

          this.updateScoredMatches([payload.match]);

          this.scoringOptions = payload.scoringOptions;
        })
        .catch((response) => {
          let message;

          if (response.status === 403) {
            message = "You do not have permission to score the match.";
          } else {
            message = ApiResponseHelper.getErrorMessageFromResponse(response);

            if (message === null) {
              message = "There was an error loading the match details.";
            }
          }

          this.loadingMatchError = message;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Handle the "updated" event from the SetResultScorer component.
     *
     * @param {string|null} matchSetID The match set id to pre-select.
     */
    onSetResultScorerUpdated(matchSetID: string | null): void {
      if (matchSetID) {
        this.queryParams.matchSetId = matchSetID;
      }

      this.loadMatch();
    },

    /**
     * Handle the "updated" event from the SideShotResultScorer component.
     *
     * @param {string|null} nextSideMatchId The next side match ready to process.
     * @param {?boolean} enableCompetitorEndScoring
     */
    onSideShotResultScorerUpdated(
      nextSideMatchId: string | null,
      enableCompetitorEndScoring?: boolean
    ): void {
      if (nextSideMatchId) {
        this.queryParams.sideMatchId = nextSideMatchId;
      } else {
        this.queryParams.sideMatchId = null;
      }

      if (typeof enableCompetitorEndScoring === "boolean") {
        this.queryParams.enableCompetitorEndScoring = enableCompetitorEndScoring;
      }

      this.loadMatch();
    },

    /**
     * Handle the "completed" event from the result scorer components.
     */
    onCompleted(): void {
      // Redirect back to "My Scored Match" page.
      this.$router.push({ name: "app-marker-matches" });
    },
  },
});
