import Vue, { PropType, VNode, CreateElement, RenderContext } from "vue";

export interface NodePropInterface {
  nodes: VNode | VNode[];
}

/**
 * Functional component which acts as a bridge for the provided rendered nodes.
 * This is useful for a more flexible approach to rendering dynamic components.
 * This is also useful for a case where we want to render components without needing
 * a root element.
 */
export default Vue.extend({
  name: "VNodeBridgeFunctional",

  functional: true,

  props: {
    /**
     * VNodes that will be rendered.
     */
    nodes: {
      type: [Object, Array] as PropType<NodePropInterface["nodes"]>,
      required: true,
    },
  },

  render(
    h: CreateElement,
    context: RenderContext<NodePropInterface>
  ): NodePropInterface["nodes"] {
    return context.props.nodes;
  },
});
