














































































import Vue, { VueConstructor } from "vue";
import { CompetitorModel } from "@memberpoint/ba-result-components";
import { VForm } from "@/types/vuetify";
import ControlActionDialogMixin from "@/controllers/app/marker-matches/scorer/Mixins/ControlActionDialogMixin";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ControlActionDialogMixin>
>).extend({
  name: "EnterScoresAction",

  mixins: [ControlActionDialogMixin],

  props: {
    competitorOne: {
      type: CompetitorModel,
      required: true,
    },

    competitorTwo: {
      type: CompetitorModel,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: "Enter Scores",
    },

    buttonLabel: {
      type: String,
      required: false,
      default: "Enter Scores",
    },
  },

  data() {
    return {
      competitorOneScore: null as number | null,
      competitorTwoScore: null as number | null,
    };
  },

  computed: {
    /**
     * Returns the short name of competitor 1.
     *
     * @return {string}
     */
    competitorOneName(): string {
      return this.competitorOne.getName();
    },

    /**
     * Returns the short name of competitor 2.
     *
     * @return {string}
     */
    competitorTwoName(): string {
      return this.competitorTwo.getName();
    },
  },

  methods: {
    /**
     * Close the dialog.
     *
     * @emits close {void}
     */
    close(): void {
      this.clearForm();

      this.closeDialog();
    },

    /**
     * Clear the form.
     */
    clearForm(): void {
      this.competitorOneScore = null;
      this.competitorTwoScore = null;
    },

    /**
     * Returns TRUE if the form passes validation; otherwise FALSE.
     *
     * @return {boolean}
     */
    validateForm(): boolean {
      if (!this.$refs.form) {
        return false;
      }

      const form = this.$refs.form as VForm;

      return form.validate();
    },

    /**
     * Handle the "submit" button event.
     */
    submit(): void {
      if (this.validateForm()) {
        this.$emit(
          "submit",
          {
            competitorOneScore: this.competitorOneScore,
            competitorTwoScore: this.competitorTwoScore,
          },
          this.close
        );
      }
    },
  },
});
