import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import { ScoringOptions } from "@/types";
import SideMatch from "@/Classes/SideMatch";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import SideShotResultManager from "@/services/SideShotResultManager";
import MatchActionsMixin from "@/controllers/app/marker-matches/scorer/Mixins/MatchActionsMixin";
import MatchResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/MatchResultScorerControlsMixin";

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchActionsMixin> &
    InstanceType<typeof MatchResultScorerControlsMixin>
>).extend({
  mixins: [MatchResultScorerControlsMixin, MatchActionsMixin],

  props: {
    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,

    sideMatch: {
      type: SideMatch as PropType<SideMatch | null>,
      required: false,
      default: null,
    },

    competitor1TotalShots: {
      type: Number,
      required: false,
      default: null,
    },

    competitor2TotalShots: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      showSideMatchActions: false,
      enteringFinalScoresForSideMatch: false,
      enteringFinalScoresForSideMatchError: null as string | null,
      markingSideMatchUnplayed: false,
      markingSideMatchUnplayedError: null as string | null,
      forfeitingSideMatch: false,
      forfeitingSideMatchError: null as string | null,
    };
  },

  computed: {
    /**
     * Returns the shots for competitor 1.
     *
     * @return {number}
     */
    competitorOneTotalShots(): number {
      if (this.competitor1TotalShots !== null) {
        return this.competitor1TotalShots;
      }

      if (!this.result) {
        return 0;
      }

      return this.result.competitorOneShots || 0;
    },

    /**
     * Returns the shots for competitor 2.
     *
     * @return {number}
     */
    competitorTwoTotalShots(): number {
      if (this.competitor2TotalShots !== null) {
        return this.competitor2TotalShots;
      }

      if (!this.result) {
        return 0;
      }

      return this.result.competitorTwoShots || 0;
    },
  },

  methods: {
    /**
     * Close the side match options dialog and optionally reload the match.
     *
     * @param silent {boolean} If FALSE will emit a "close" event.
     */
    closeSideMatchActions(silent = false): void {
      this.showSideMatchActions = false;

      if (!silent) {
        this.$emit("close");
      }
    },

    /**
     * Handle the "submit" event from the EnterScoresAction component for a side match.
     *
     * @param {{ competitorOneScore: number, competitorTwoScore: number}} scores
     */
    onEnterFinalScoresForSideMatch(scores: {
      competitorOneScore: number;
      competitorTwoScore: number;
    }): void {
      if (this.enteringFinalScoresForSideMatch) {
        return;
      }

      if (this.sideMatch === null) {
        return;
      }

      this.enteringFinalScoresForSideMatch = true;
      this.enteringFinalScoresForSideMatchError = null;

      const formatID = this.sideMatch.format.id;
      const manager = new SideShotResultManager();

      manager
        .enterFinalScoresForSideMatch(
          this.match.id,
          formatID,
          scores.competitorOneScore,
          scores.competitorTwoScore,
          true
        )
        .then(() => {
          this.closeSideMatchActions();
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem trying to enter final scores.";
          }

          this.enteringFinalScoresForSideMatchError = message;
        })
        .finally(() => {
          this.enteringFinalScoresForSideMatch = false;
        });
    },

    /**
     * Handle the "submit" event from the ForfeitSideMatchAction component.
     *
     * @param {string} forfeitingTeamId
     * @param {Function} closeDialog
     */
    onForfeitSideMatch(
      forfeitingTeamId: string,
      closeDialog: () => void
    ): void {
      if (this.forfeitingSideMatch) {
        return;
      }

      if (this.sideMatch === null) {
        return;
      }

      this.forfeitingSideMatch = true;
      this.forfeitingSideMatchError = null;

      const manager = new SideShotResultManager();

      manager
        .forfeitSideMatch(
          this.match.id,
          this.sideMatch.format.id,
          forfeitingTeamId as string
        )
        .then(() => {
          closeDialog();

          this.closeSideMatchActions();
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was an error attempting to forfeit the rink.";
          }

          this.forfeitingSideMatchError = message;
        })
        .finally(() => {
          this.forfeitingSideMatch = false;
        });
    },

    /**
     * Handle the "submit" event from the MarkSideMatchUnplayedAction component.
     *
     * @param {Function} closeDialog
     */
    onMarkSideMatchUnplayed(closeDialog: () => void): void {
      if (this.markingSideMatchUnplayed) {
        return;
      }

      if (this.sideMatch === null) {
        return;
      }

      this.markingSideMatchUnplayed = true;
      this.markingSideMatchUnplayedError = null;

      const manager = new SideShotResultManager();

      manager
        .setSideMatchAsUnplayed(
          this.sideMatch.matchID,
          this.sideMatch.format.id
        )
        .then(() => {
          closeDialog();
          this.closeSideMatchActions();
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem marking the rink as UNPLAYED.";
          }

          this.markingSideMatchUnplayedError = message;
        })
        .finally(() => {
          this.markingSideMatchUnplayed = false;
        });
    },
  },
});
