































































































































import { EndModel } from "@memberpoint/ba-result-components";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import MatchResult from "@/Classes/MatchResult";
import MatchStateAlert from "@/components/MatchStateAlert/index.vue";
import { ScoringOptions } from "@/types";
import MarkerResultScorerControls from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/Standard/ResultScorerControls/MarkerResultScorerControls.vue";
import CompetitorResultScorerControls from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/Standard/ResultScorerControls/CompetitorResultScorerControls.vue";
import MatchExtModel from "@/Classes/MatchExtModel";
import MatchBlock from "@/components/MatchBlock/index.vue";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof MatchDetailsMixin>
>).extend({
  name: "StandardResultScorer",

  components: {
    MatchBlock,
    CompetitorResultScorerControls,
    MarkerResultScorerControls,
    MatchStateAlert,
  },

  mixins: [MatchDetailsMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,
  },

  data() {
    return {
      ends: [] as EndModel[],
      loadingEnds: false,
      loadingEndsErrors: [] as string[],
      matchData: this.match,
      errorMessage: null as string | null,
      showErrorDialog: false,
    };
  },

  created() {
    if (this.match.matchCapabilities.canEnterEndScore) {
      this.loadEnds();
    }
  },

  methods: {
    /**
     * Load the ends for the match.
     */
    loadEnds(): void {
      if (this.loadingEnds) {
        return;
      }

      const manager = new CompetitionScoringManager();

      this.loadingEnds = true;
      this.loadingEndsErrors = [];

      const result = this.match.result as MatchResult;

      manager
        .getEndsForOwner(result.handle)
        .then((ends: EndModel[]) => {
          this.ends = ends;
        })
        .catch((error) => {
          this.loadingEndsErrors = CompetitionScoringManager.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.loadingEnds = false;
        });
    },

    /**
     * Handle the "close" event from the MarkerResultScorerControl component.
     */
    onCloseControls(): void {
      // Emit an "updated" event so the parent can reload if needed.
      this.$emit("updated");
    },

    /**
     * Handle the "mark-match-completed" event from the MarkerResultScorerControl component.
     */
    onMatchCompleted(): void {
      this.$emit("completed");
    },

    /**
     * Handle the "undo-last-end-submitted" from the MarkerResultScorerControls component.
     */
    onSubmitUndoLastEnd(): void {
      this.loadEnds();
    },

    /**
     * Handle the "finalize-bye" event from the MarkerResultScorerControls component.
     */
    onFinalizeBye(): void {
      this.$emit("updated");
    },

    /**
     * Handle the "final-scores-entered" event from the MarkerResultScorerControls component.
     */
    onFinalScoresEntered(): void {
      this.$emit("updated");
    },

    /**
     * Handle the "end-scores-completed" from the CompetitorResultScorerControls component.
     */
    onEndScoresCompleted(): void {
      this.loadEnds();
    },

    /**
     * Handle the "ends-completed" event from the CompetitorResultScorerControls component.
     */
    onEndsCompleted(): void {
      this.$emit("updated");
    },

    /**
     * Handle "error" event from any child components and display the error message.
     *
     * @param {string} message
     */
    onError(message: string): void {
      this.errorMessage = message;
      this.showErrorDialog = true;
    },
  },
});
