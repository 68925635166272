
















































































































































































































































import Vue, { VueConstructor } from "vue";
import MatchExtModel from "@/Classes/MatchExtModel";
import { AxiosResponse } from "axios";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import CompetitorScorePollingMixin from "@/controllers/app/marker-matches/scorer/Mixins/CompetitorScorePollingMixin";
import EndScorer from "@/components/EndScorer/index.vue";
import {
  CompleteEndsPayload,
  EndScoringState,
} from "@/services/CompetitorScoringManager";
import { CompetitorScoringManager } from "@/services/CompetitorScoringManager";
import _get from "lodash/get";

interface EndResultProxyData {
  endNumber: number;
  competitor1Score: number;
  competitor2Score: number;
  enteredByCompetitor: string;
}

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchDetailsMixin> &
    InstanceType<typeof CompetitorScorePollingMixin>
>).extend({
  name: "CompetitorEndScorer",

  components: { EndScorer },

  mixins: [MatchDetailsMixin, CompetitorScorePollingMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scorableHandle: {
      type: String,
      required: true,
    },

    endNumber: {
      type: Number,
      required: true,
    },

    completeEndsButtonLabel: {
      type: String,
      required: false,
      default: "Complete",
    },
  },

  data() {
    return {
      matchData: this.match,
      submittingEnd: false,
      disableScorer: false,
      showWaitingScoreConfirmationDialog: false,
      showWaitingCompletionConfirmationDialog: false,
      submittingUndoEnteredScores: false,
      submittingUndoEnteredScoresError: null as string | null,
      awayTeamConfirming: false,
      showConfirmationScoreErrorDialog: false,
      endResultProxyData: null as EndResultProxyData | null,
      previousHasEnd: null as boolean | null,
      completingEnds: false,
      completingEndsError: null as string | null,
      showAlreadyRequestCompleteEndsError: false,
      hasCompleteEndsRequestSnackbarShown: false,
      cancellingCompletionRequest: false,
      cancellingCompletionRequestError: null as string | null,
    };
  },

  watch: {
    endNumber: {
      immediate: true,
      handler(nv) {
        this.startPollingEndScoringState(
          nv,
          this.scorableHandle,
          this.handleEndScoringState
        );
      },
    },
  },

  destroyed() {
    // When the component is removed we should stop any polling requests.
    this.stopPollingEndScoringState();
  },

  methods: {
    /**
     * Clear the score inputs from the EndScorer component.
     */
    clearScorer() {
      if (this.$refs.scorer && typeof this.$refs.scorer === "object") {
        const scorer = this.$refs.scorer as InstanceType<typeof EndScorer>;

        typeof scorer.clear === "function" && scorer.clear();
      }
    },

    /**
     * Close all dialogs.
     */
    closeDialogs(): void {
      this.showWaitingScoreConfirmationDialog = false;
      this.showWaitingCompletionConfirmationDialog = false;
      this.closeConfirmationScoreErrorDialog();
    },

    /**
     * Clear all errors.
     */
    clearErrors(): void {
      this.submittingUndoEnteredScoresError = null;
      this.completingEndsError = null;
      this.cancellingCompletionRequestError = null;
    },

    /**
     * Handle end scoring state.
     */
    handleEndScoringState(endScoringState: EndScoringState) {
      const currentCompetitor = this.matchData.getCurrentUserCompetitor();

      // If we can no longer enter end proxies maybe because the result
      // has already been finalized then just signal to complete ends.
      if (!endScoringState.canScoreEnds) {
        this.closeDialogs();
        this.$emit("ends-completed");
        return;
      }

      if (endScoringState.completeEndsAction) {
        // When the ends have been completed, just tell the parent
        // that the ends have been completed.
        if (endScoringState.completeEndsAction.isConfirmed) {
          this.closeDialogs();
          this.$emit("ends-completed");
          return;
        }

        if (currentCompetitor) {
          // Display a snackbar for the competitor that DID NOT request for
          // the completion of the ends.
          if (
            endScoringState.completeEndsAction.initiatedByCompetitor !==
            currentCompetitor.id
          ) {
            if (!this.hasCompleteEndsRequestSnackbarShown) {
              const otherCompetitor = this.matchData.getNonCurrentUserCompetitor();

              let message = "";

              if (otherCompetitor) {
                message = `<strong>${otherCompetitor.getName()}</strong> requested to complete the ends.`;
              } else {
                message = "A competitor has requested to complete the ends.";
              }

              this.$snackbar.info({ content: message, html: true });

              this.hasCompleteEndsRequestSnackbarShown = true;
            }
          } else {
            // If the completion request was initiated by the current competitor,
            // display the waiting confirmation dialog.
            this.showWaitingCompletionConfirmationDialog = true;
            return;
          }
        }
      } else {
        // No complete ends requested. This can also mean it has been cancelled.
        this.hasCompleteEndsRequestSnackbarShown = false;
        this.showWaitingCompletionConfirmationDialog = false;
      }

      if (endScoringState.hasEnd) {
        this.closeDialogs();

        // Since we already have end result for this end number
        // then we do not need to keep polling.
        this.stopPollingEndScoringState();

        // Display a snackbar ONLY for the competitor that entered the scores.
        if (
          typeof this.previousHasEnd === "boolean" &&
          endScoringState.hasEnd !== this.previousHasEnd
        ) {
          if (
            currentCompetitor &&
            currentCompetitor.id === endScoringState.endProxyEnteredByCompetitor
          ) {
            this.$snackbar.success({
              content: `Scores at <strong>End ${endScoringState.atEndNumber}</strong> confirmed!`,
              html: true,
            });
          }
        }

        this.$emit("end-scores-completed", endScoringState);
      } else {
        // A competitor has entered an end score
        if (endScoringState.hasEndProxy) {
          // If the scores entered was by the current competitor
          // Then display a confirmation dialog.
          if (
            currentCompetitor &&
            currentCompetitor.id === endScoringState.endProxyEnteredByCompetitor
          ) {
            this.showWaitingScoreConfirmationDialog = true;
            this.awayTeamConfirming = false;
          } else {
            this.showWaitingScoreConfirmationDialog = false;
            this.awayTeamConfirming = true;
          }
        } else {
          // No scores have entered for the current end yet.
          this.awayTeamConfirming = false;
          this.closeDialogs();
          this.clearErrors();
        }
      }

      this.previousHasEnd = endScoringState.hasEnd;
    },

    /**
     * Close the dialog for score confirmation error.
     */
    closeConfirmationScoreErrorDialog() {
      this.endResultProxyData = null;
      this.showConfirmationScoreErrorDialog = false;
    },

    /**
     * Handle the "click" event from the button to request complete ends.
     */
    onCompleteEnds(): void {
      if (this.completingEnds) {
        return;
      }

      this.completingEnds = true;

      const manager = new CompetitorScoringManager();

      manager
        .completeEnds(this.scorableHandle)
        .then((completeEndsPayload: CompleteEndsPayload) => {
          if (completeEndsPayload.isConfirmed) {
            this.$emit("ends-completed", completeEndsPayload);
          } else {
            // Display the dialog to wait for confirmation from the other team.
            this.showWaitingCompletionConfirmationDialog = true;
          }
        })
        .catch((response) => {
          const data = response.data || {};

          if (
            response.status === 417 &&
            Array.isArray(data.data) &&
            data.data.length > 0
          ) {
            const failureReason = _get(data.data[0], "attributes.reason");

            if (failureReason === "COMPLETE_ENDS_ALREADY_INITIATED") {
              this.showAlreadyRequestCompleteEndsError = true;
              return;
            }
          }

          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem trying to request to complete ends.";
          }

          this.$emit("error", message);
        })
        .finally(() => {
          this.completingEnds = false;
        });
    },

    /**
     * Handle the "click" event from the button component in
     * the dialog for the competitor that entered scores to re-update
     * their entered scores.
     */
    onSubmitUpdateScores(): void {
      if (this.submittingUndoEnteredScores) {
        return;
      }

      this.submittingUndoEnteredScores = true;
      this.submittingUndoEnteredScoresError = null;

      const manager = new CompetitorScoringManager();

      manager
        .removeEndScores(this.scorableHandle, this.endNumber)
        .then(() => {
          this.showWaitingScoreConfirmationDialog = false;
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem trying to undo your entered scores.";
          }

          this.submittingUndoEnteredScoresError = message;
        })
        .finally(() => {
          this.submittingUndoEnteredScores = false;
        });
    },

    /**
     * Handle the "click" event from the button component in the dialog
     * for cancelling the request to complete ends.
     */
    onCancelCompletionRequest(): void {
      if (this.cancellingCompletionRequest) {
        return;
      }

      this.cancellingCompletionRequest = true;
      this.cancellingCompletionRequestError = null;

      const manager = new CompetitorScoringManager();

      manager
        .removeCompleteEndsRequest(this.scorableHandle)
        .then(() => {
          // Close the completion confirmation dialog.
          this.showWaitingCompletionConfirmationDialog = false;
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message =
              "There was a problem trying to cancel the request to complete ends.";
          }

          this.cancellingCompletionRequestError = message;
        })
        .finally(() => {
          this.cancellingCompletionRequest = false;
        });
    },

    /**
     * Handles the "submit" event from the scorer component.
     *
     * @param {{competitorOneScore: number, competitorTwoScore}} scores
     */
    onEndScoreSubmit(scores: {
      competitorOneScore: number;
      competitorTwoScore: number;
    }): void {
      if (this.submittingEnd) {
        return;
      }

      this.submittingEnd = true;

      const manager = new CompetitorScoringManager();

      const attributes = {
        endNumber: this.endNumber,
        competitorOneScore: scores.competitorOneScore,
        competitorTwoScore: scores.competitorTwoScore,
      };

      manager
        .enterEndScores(this.scorableHandle, attributes)
        .then((endScoringState: EndScoringState) => {
          this.clearScorer();

          this.handleEndScoringState(endScoringState);

          this.$emit("submit", attributes);
        })
        .catch((response: AxiosResponse) => {
          const data = response.data || {};

          if (
            response.status === 417 &&
            Array.isArray(data.errors) &&
            data.errors.length > 0
          ) {
            const failureReason = _get(
              data.errors[0],
              "meta.params.failureReason"
            );

            if (
              failureReason &&
              failureReason === "INCORRECT_CONFIRMED_SCORE"
            ) {
              this.showConfirmationScoreErrorDialog = true;

              this.endResultProxyData = _get(
                data.errors[0],
                "meta.params.endResultProxy"
              );

              return;
            }
          }

          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = `There was a problem submitting the scores at end ${this.endNumber}.`;
          }

          this.$emit("error", message);
        })
        .finally(() => {
          this.submittingEnd = false;
        });
    },
  },
});
