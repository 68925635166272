















































































































import EndScorer from "@/components/EndScorer/index.vue";
import { EndModel } from "@memberpoint/ba-result-components";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import { AxiosResponse } from "axios";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import CompleteSideMatchAction from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchActions/CompleteSideMatchAction.vue";
import EnterScoresAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/EnterScoresAction.vue";
import SideShotResultManager from "@/services/SideShotResultManager";
import MarkSideMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchActions/MarkSideMatchUnplayedAction.vue";
import ForfeitSideMatchAction from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchActions/ForfeitSideMatchAction.vue";
import UndoLastEndDialog from "@/controllers/app/marker-matches/scorer/_internal/UndoLastEndDialog.vue";
import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";
import SetMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/SetMatchUnplayedAction.vue";
import ForfeitMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ForfeitMatchAction.vue";
import FinalizeByeAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/FinalizeByeAction.vue";
import EndScoringMixin from "@/controllers/app/marker-matches/scorer/Mixins/EndScoringMixin";
import Vue, { VNode, VueConstructor } from "vue";
import SideShotResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/SideShotResultScorerControlsMixin";
import SideMatch from "@/Classes/SideMatch";
import { VDivider } from "vuetify/lib";
import VNodeBridgeFunctional from "@/components/VNodeBridge/VNodeBridgeFunctional";
import { MatchActionVNode } from "@/controllers/app/marker-matches/scorer/types";

/**
 * Internal component for managing controls for side shot result that is used
 * by markers.
 *
 * @internal
 */
export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof SideShotResultScorerControlsMixin> &
    InstanceType<typeof EndScoringMixin>
>).extend({
  name: "MarkerSideShotResultScorerControls",

  components: {
    VNodeBridgeFunctional,
    FinalizeByeAction,
    UndoLastEndDialog,
    EndScorer,
  },

  mixins: [SideShotResultScorerControlsMixin, EndScoringMixin],

  data() {
    return {
      submittingEnd: false,
      completingSideMatch: false,
      completingSideMatchError: null as string | null,
      completingMatch: false,
      completingMatchError: null as string | null,
      showUnableToCompleteMatchDialog: false,
    };
  },

  computed: {
    /**
     * Returns the number of ends that can be added.
     *
     * @return {?number}
     */
    numberOfEndsLeft(): number | null {
      if (typeof this.scoringOptions.endsPerMatch === "number") {
        return this.scoringOptions.endsPerMatch > this.ends.length
          ? this.scoringOptions.endsPerMatch - this.ends.length
          : 0;
      }

      return null;
    },

    /**
     * Returns TRUE if we can enter end scores; otherwise FALSE.
     *
     * @return {boolean}
     */
    canEnterEndScores(): boolean {
      return (
        this.sideMatch !== null &&
        this.matchData.matchCapabilities.canEnterEndScore &&
        !this.sideMatch.isFinalized
      );
    },

    /**
     * Returns TRUE if we can display the action to undo the last end; otherwise FALSE.
     *
     * @return {boolean}
     */
    canUndoLastEnd(): boolean {
      return this.canEnterEndScores && this.hasEnds;
    },

    /**
     * Returns TRUE if we should display the actions to manage match; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayManageMatchActions(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.matchActionNodeCount > 0;
    },

    /**
     * Returns TRUE if we should display the actions for the selected side match; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayActionsForSideMatch(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.sideMatchActionNodeCount > 0;
    },

    /**
     * Returns the VNodes for the side match actions.
     *
     * @return {VNode[]}
     */
    renderedSideMatchActions(): VNode[] {
      if (this.sideMatch === null) {
        return [];
      }

      return this._renderSideMatchActions(this.sideMatch);
    },

    /**
     * Returns the VNodes for the main match actions.
     *
     * @return {VNode[]}
     */
    renderedMatchActions(): VNode[] {
      if (this.sideMatch !== null) {
        return [];
      }

      return this._renderMatchActions();
    },

    /**
     * Returns the number of actual match action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    matchActionNodeCount(): number {
      let count = 0;

      this.renderedMatchActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },

    /**
     * Returns the number of actual side-match action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    sideMatchActionNodeCount(): number {
      let count = 0;

      this.renderedSideMatchActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },
  },

  methods: {
    /**
     * Handle the "submit" from the CompleteSideMatchAction component.
     *
     * @param {Function} closeDialog
     */
    onSideMatchCompleted(closeDialog: () => void): void {
      if (this.completingSideMatch) {
        return;
      }

      if (!this.sideMatch) {
        return;
      }

      this.completingSideMatch = true;
      this.completingSideMatchError = null;

      const manager = new SideShotResultManager();

      manager
        .finalizeSideMatch(this.sideMatch.matchID, this.sideMatch.format.id)
        .then(() => {
          closeDialog();
          this.closeSideMatchActions();
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message =
              "There was an error attempting to mark the rink as completed.";
          }

          this.completingSideMatchError = message;
        })
        .finally(() => {
          this.completingSideMatch = false;
        });
    },

    /**
     * Handles the "submit" event from the EndScorer component.
     *
     * @param {{competitorOneScore: number, competitorTwoScore}} scores
     */
    onEndScoreSubmit(scores: {
      competitorOneScore: number;
      competitorTwoScore: number;
    }): void {
      if (this.submittingEnd) {
        return;
      }

      if (!this.sideMatch) {
        return;
      }

      this.submittingEnd = true;

      const manager = new CompetitionScoringManager();

      manager
        .createEndForOwner(this.sideMatch.handle, {
          endNumber: this.nextEndNumber,
          competitorOneScore: scores.competitorOneScore,
          competitorTwoScore: scores.competitorTwoScore,
        })
        .then((end: EndModel) => {
          // Add the new end to the end array so that the scorecard gets updated.
          this.ends.push(end);

          this.clearScorer();
        })
        .catch((response: AxiosResponse) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem submitting the end.";
          }

          // Sending an "error" event with the error message to the parent
          // to handle.
          this.$emit("error", message);
        })
        .finally(() => {
          this.submittingEnd = false;
        });
    },

    /**
     * Handle the "submit" event from the UndoLastEndDialog component.
     */
    onSubmitUndoLastEnd(): void {
      if (this.sideMatch !== null) {
        this.$emit("undo-last-end-submitted", this.sideMatch);
      }
    },

    /**
     * Handle the "update" from the FinalizeByeAction component.
     */
    onFinalizeBye(): void {
      this.$emit("finalize-bye");
    },

    /**
     * Render the actions for the main match.
     *
     * @return {VNode[]}
     *
     * @private
     */
    _renderMatchActions(): VNode[] {
      const actionNodes: VNode[] = [];

      const divider = this.$createElement(VDivider);

      if (this.matchData.matchCapabilities.canFinalize) {
        actionNodes.push(
          this.createMatchActionVNode(CompleteMatchAction, {
            props: {
              match: this.matchData,
              competitorOneTotalShots: this.competitorOneTotalShots,
              competitorTwoTotalShots: this.competitorTwoTotalShots,
              loading: this.completingMatch,
              error: this.completingMatchError,
            },
            on: {
              submit: this.onMarkMatchCompleted,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.completingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsUnplayed) {
        actionNodes.push(
          this.createMatchActionVNode(SetMatchUnplayedAction, {
            props: {
              loading: this.markingMatchUnplayed,
              error: this.markingMatchUnplayedError,
            },
            on: {
              submit: this.onMarkMatchUnplayed,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.markingMatchUnplayedError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsForfeit) {
        actionNodes.push(
          this.createMatchActionVNode(ForfeitMatchAction, {
            props: {
              match: this.matchData,
              loading: this.forfeitingMatch,
              error: this.forfeitingMatchError,
            },
            on: {
              submit: this.onForfeitMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.forfeitingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      return actionNodes;
    },

    /**
     * Render the actions for the side match.
     *
     * @param {SideMatch} sideMatch
     * @return {VNode[]}
     *
     * @private
     */
    _renderSideMatchActions(sideMatch: SideMatch): VNode[] {
      const actionNodes: VNode[] = [];

      if (sideMatch.isFinalized) {
        return actionNodes;
      }

      const divider = this.$createElement(VDivider);

      if (this.canEnterEndScores) {
        actionNodes.push(
          this.createMatchActionVNode(CompleteSideMatchAction, {
            props: {
              match: this.matchData,
              sideMatch: this.sideMatch,
              scoringOptions: this.scoringOptions,
              competitorOneTotalShots: this.competitorOneTotalEndShots,
              competitorTwoTotalShots: this.competitorTwoTotalEndShots,
              loading: this.completingSideMatch,
              error: this.completingSideMatchError,
            },
            on: {
              submit: this.onSideMatchCompleted,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.completingSideMatchError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canEnterResults) {
        actionNodes.push(
          this.createMatchActionVNode(EnterScoresAction, {
            props: {
              competitorOne: this.matchData.competitorOne,
              competitorTwo: this.matchData.competitorTwo,
              title: "Enter Final Scores for Rink",
              buttonLabel: "Enter end-of-game Scores",
              loading: this.enteringFinalScoresForSideMatch,
              error: this.enteringFinalScoresForSideMatchError,
            },
            on: {
              submit: this.onEnterFinalScoresForSideMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.enteringFinalScoresForSideMatchError = null;
              },
            },
            scopedSlots: {
              description: () => {
                return [
                  "Entering end-of-game-scores will ",
                  this.$createElement(
                    "strong",
                    {
                      class: "error--text",
                    },
                    "wipe all end results"
                  ),
                  " and select the winner based on the values entered below.",
                ];
              },
            },
          }),
          divider
        );

        actionNodes.push(
          this.createMatchActionVNode(MarkSideMatchUnplayedAction, {
            props: {
              error: this.markingSideMatchUnplayedError,
              loading: this.markingSideMatchUnplayed,
            },
            on: {
              submit: this.onMarkSideMatchUnplayed,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.markingSideMatchUnplayedError = null;
              },
            },
          }),
          divider
        );

        actionNodes.push(
          this.createMatchActionVNode(ForfeitSideMatchAction, {
            props: {
              match: this.matchData,
              error: this.forfeitingSideMatchError,
              loading: this.forfeitingSideMatch,
            },
            on: {
              submit: this.onForfeitSideMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.forfeitingSideMatchError = null;
              },
            },
          }),
          divider
        );
      }

      return actionNodes;
    },
  },
});
