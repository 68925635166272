












































































import Vue, { VueConstructor } from "vue";
import ControlActionDialogMixin from "@/controllers/app/marker-matches/scorer/Mixins/ControlActionDialogMixin";
import MatchExtModel from "@/Classes/MatchExtModel";

interface Competitor {
  name: string;
  totalShots: number;
}

interface WinnerLoserCompetitor {
  winner: Competitor | null;
  loser: Competitor | null;
}

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ControlActionDialogMixin>
>).extend({
  name: "CompleteMatchAction",

  mixins: [ControlActionDialogMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    competitorOneTotalShots: {
      type: Number,
      required: false,
      default: 0,
    },

    competitorTwoTotalShots: {
      type: Number,
      required: false,
      default: 0,
    },

    title: {
      type: String,
      required: false,
      default: "Mark as Completed",
    },
  },

  computed: {
    /**
     * Returns the details of the winner and loser competitor.
     * If both competitor is NULL then the match is currently a DRAW.
     *
     * @return {WinnerLoserCompetitor}
     */
    winnerLoserCompetitor(): WinnerLoserCompetitor {
      const winnerLoser: WinnerLoserCompetitor = {
        winner: null,
        loser: null,
      };

      if (this.competitorOneTotalShots > this.competitorTwoTotalShots) {
        winnerLoser.winner = {
          name: this.match.competitorOne.getName(),
          totalShots: this.competitorOneTotalShots,
        };

        winnerLoser.loser = {
          name: this.match.competitorTwo.getName(),
          totalShots: this.competitorTwoTotalShots,
        };
      } else if (this.competitorTwoTotalShots > this.competitorOneTotalShots) {
        winnerLoser.winner = {
          name: this.match.competitorTwo.getName(),
          totalShots: this.competitorTwoTotalShots,
        };

        winnerLoser.loser = {
          name: this.match.competitorOne.getName(),
          totalShots: this.competitorOneTotalShots,
        };
      }

      return winnerLoser;
    },
  },
});
