







































































import Vue from "vue";
import { CompetitorModel } from "@memberpoint/ba-result-components";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import MatchExtModel from "@/Classes/MatchExtModel";

export default Vue.extend({
  name: "FinalizeByeAction",

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      loading: false,
      hasError: false,
    };
  },

  computed: {
    /**
     * Returns the winner competitor which is the competitor
     * that is not a BYE.
     *
     * @return {CompetitorModel}
     */
    winnerCompetitor(): CompetitorModel {
      if (this.match.competitorOne.isBye()) {
        return this.match.competitorTwo;
      }

      return this.match.competitorOne;
    },
  },

  methods: {
    finalizeBye() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.hasError = false;
      const manager = new CompetitionScoringManager();

      manager
        .markMatchAsCompleted(this.match.id)
        .then(() => {
          this.showDialog = false;
          this.$emit("update");
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * When the close button is clicked
     * @emits close {void}
     */
    onCloseClick() {
      this.showDialog = false;
      this.hasError = false;
      this.$emit("close");
    },
  },
});
