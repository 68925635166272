
































































































































































import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import LoadSetMatchesMixin from "@/controllers/app/marker-matches/scorer/Mixins/LoadSetMatchesMixin";
import MatchExtModel from "@/Classes/MatchExtModel";
import { ScoringOptions, SelectItem } from "@/types";
import { EndModel } from "@memberpoint/ba-result-components";
import { mdiAccountGroupOutline } from "@mdi/js";
import MatchSet from "@/Classes/MatchSet";
import MatchBlock from "@/components/MatchBlock/index.vue";
import BottomSheetSelection from "@/components/BottomSheetSelection/index.vue";
import MatchSetOverview from "@/controllers/app/marker-matches/scorer/MatchSetOverview.vue";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import MarkerSetResultScorerControls from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/Set/ResultScorerControls/MarkerSetResultScorerControls.vue";
import MatchStateAlert from "@/components/MatchStateAlert/index.vue";
import SetResultManager from "@/services/SetResultManager";

const MANAGE_MATCH_OPTION = "--MANAGE_MATCH_OPTION--";

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchDetailsMixin> &
    InstanceType<typeof LoadSetMatchesMixin>
>).extend({
  name: "SetResultScorer",
  components: {
    MatchStateAlert,
    MarkerSetResultScorerControls,
    MatchSetOverview,
    BottomSheetSelection,
    MatchBlock,
  },
  mixins: [MatchDetailsMixin, LoadSetMatchesMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,

    matchSetId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      loadingEnds: false,
      loadingEndsError: null as string | null,
      ends: [] as EndModel[],
      errorMessage: null as string | null,
      showErrorDialog: false,
      groupIcon: mdiAccountGroupOutline,
      matchSets: [] as MatchSet[],
      selected: (this.matchSetId || MANAGE_MATCH_OPTION) as string | null,
      matchData: this.match,
      shouldReloadMatchSets: false,
      competitor1SetsWon: null as number | null,
      competitor2SetsWon: null as number | null,
      loadingMatchState: false,
      nonScoredSets: [] as string[],
    };
  },

  computed: {
    /**
     * Returns TRUE if we can remove the set. Currently, only the last set is removable.
     *
     * @return {boolean}
     */
    canRemoveSet(): boolean {
      return (
        this.selectedMatchSet !== null &&
        this.matchSets.length > 1 &&
        this.selectedMatchSet.setNumber === this.matchSets.length
      );
    },

    /**
     * Returns the match set options for the select dropdown.
     *
     * @return {SelectItem<string>[]}
     */
    manageMatchSetSelectOptions(): SelectItem<string>[] {
      const options: SelectItem<string>[] = [
        {
          text: "View Match Overview",
          value: MANAGE_MATCH_OPTION,
        },
      ];

      this.matchSets.forEach((matchSet: MatchSet) => {
        let text = `Set ${matchSet.setNumber}`;

        if (matchSet.isTieBreak) {
          text += " (Tie-break)";
        }

        options.push({ text, value: matchSet.id });
      });

      return options;
    },

    /**
     * Returns TRUE if the "Match Overview" option is selected; otherwise FALSE.
     *
     * @return {boolean}
     */
    isManageMatchOptionSelected(): boolean {
      return this.selected === MANAGE_MATCH_OPTION;
    },

    /**
     * Returns TRUE if there is a tie-break set.
     *
     * @return {boolean}
     */
    hasTieBreakSet(): boolean {
      for (const matchSet of this.matchSets) {
        if (matchSet.isTieBreak) {
          return true;
        }
      }

      return false;
    },

    /**
     * Returns the ID of the set that is currently selected.
     *
     * @return {string|null}
     */
    selectedMatchSetID(): string | null {
      if (
        typeof this.selected === "string" &&
        this.selected !== MANAGE_MATCH_OPTION
      ) {
        return this.selected;
      }

      return null;
    },

    /**
     * Returns the current selected set.
     *
     * @return {MatchSet|null}
     */
    selectedMatchSet(): MatchSet | null {
      if (this.selectedMatchSetID === null) {
        return null;
      }

      return this.getMatchSetByID(this.selectedMatchSetID);
    },
  },

  watch: {
    /**
     * Watch for changes in match set selection.
     *
     * @param {MatchSet|null} newValue
     */
    selectedMatchSet(newValue: MatchSet | null) {
      if (newValue !== null) {
        this.loadEndsForMatchSet(newValue);
      } else {
        this.ends = [];
      }
    },

    /**
     * Watch for when the manage match select option is selected.
     *
     * @param {boolean} newValue
     */
    isManageMatchOptionSelected(newValue: boolean) {
      if (newValue && this.shouldReloadMatchSets) {
        this.loadMatchSets();

        this.shouldReloadMatchSets = false;
      }
    },

    match: {
      handler(newValue: MatchExtModel) {
        this.matchData = newValue;

        this.loadMatchSets();
      },
    },
  },

  created() {
    this.matchData = this.match;

    this.loadMatchSets(this.status === "pending");
  },

  methods: {
    /**
     * Returns the match set matching the given ID or NULL if there is none selected.
     *
     * @return {MatchSet|null}
     */
    getMatchSetByID(id: string): MatchSet | null {
      const index = this.matchSets.findIndex((matchSet) => matchSet.id === id);

      if (index === -1) {
        return null;
      }

      return this.matchSets[index] || null;
    },

    /**
     * Load the sets for match.
     *
     * @param {boolean} preselectSet Whether to pre-select the selected set once sets have been loaded
     */
    loadMatchSets(preselectSet = false): void {
      if (this.isBye) {
        return;
      }

      this.loadSetsForMatch(this.match, true).then((matchSets) => {
        this.matchSets = matchSets;

        let competitor1SetsWon = 0;
        let competitor2SetsWon = 0;
        const nonScoredSets: string[] = [];

        this.matchSets.forEach((matchSet) => {
          const competitor1Shots = matchSet.competitorOneShots ?? 0;
          const competitor2Shots = matchSet.competitorTwoShots ?? 0;

          if (competitor1Shots > competitor2Shots) {
            competitor1SetsWon++;
          } else if (competitor1Shots < competitor2Shots) {
            competitor2SetsWon++;
          }

          if (
            matchSet.competitorOneShots === null &&
            matchSet.competitorTwoShots === null
          ) {
            nonScoredSets.push(matchSet.handle);
          }
        });

        this.competitor1SetsWon = competitor1SetsWon;
        this.competitor2SetsWon = competitor2SetsWon;
        this.nonScoredSets = nonScoredSets;

        if (!preselectSet) {
          return;
        }

        // If a match set ID is provided make sure it matches one in
        // the loaded set matches. If it is not then we default the
        // selection to the match overview.
        if (this.matchSetId) {
          const index = this.matchSets.findIndex(
            (matchSet) => matchSet.id === this.matchSetId
          );

          if (index === -1) {
            this.selected = MANAGE_MATCH_OPTION;
          }
        } else if (
          this.matchSets.length === 1 &&
          this.matchSets[0].competitorOneShots === null &&
          this.matchSets[0].competitorTwoShots === null
        ) {
          // If there is only 1 set, and it does not have scores entered (both competitor shots are NULL)
          // then we assume this is the initial state of the scoring (the match is started with 1 empty set).
          // We should pre-select this new empty set.
          this.selected = this.matchSets[0].id;
        }
      });
    },

    /**
     * Load the ends for the match set.
     *
     * @param {MatchSet} matchSet
     */
    loadEndsForMatchSet(matchSet: MatchSet): void {
      this.loadingEnds = true;
      this.loadingEndsError = null;

      const markerManager = new CompetitionScoringManager();

      markerManager
        .getEndsForOwner(matchSet.handle)
        .then((ends: EndModel[]) => {
          this.ends = ends;
        })
        .catch((error) => {
          this.loadingEndsError = ApiResponseHelper.getErrorMessageFromResponse(
            error
          );
        })
        .finally(() => {
          this.loadingEnds = false;
        });
    },

    /**
     * Load the current match state for set scoring.
     */
    loadMatchState(): void {
      if (this.loadingMatchState) {
        return;
      }

      this.loadingMatchState = true;

      const manager = new SetResultManager();

      manager
        .getMatchState(this.match.id)
        .then((payload) => {
          this.match.setMatchCapabilities(payload.matchCapabilities);

          this.competitor1SetsWon = payload.competitorOneSetsWon;
          this.competitor2SetsWon = payload.competitorTwoSetsWon;
          this.nonScoredSets = payload.nonScoredSets;
        })
        .finally(() => {
          this.loadingMatchState = false;
        });
    },

    /**
     * Handle the "close" event from the MarkerSetResultScorerControl component.
     */
    onCloseControls(): void {
      // Emit an "updated" event so the parent can reload if needed.
      this.$emit("updated");
    },

    /**
     * Handle the "end-submitted" event from the EndScorer component.
     */
    onEndSubmitted(): void {
      this.loadMatchState();

      this.shouldReloadMatchSets = true;
    },

    /**
     * Handle the "finalize-bye" event from the MarkerSetResultScorerControls component.
     */
    onFinalizeBye(): void {
      this.$emit("updated");
    },

    /**
     * Handle the "undo-last-end-submitted" event from the MarkerSetResultScorerControls component.
     *
     * @param {MatchSet} matchSet
     */
    onSubmitUndoLastEnd(matchSet: MatchSet): void {
      // Reload the ends for the match set.
      this.loadEndsForMatchSet(matchSet);

      this.loadMatchState();

      this.shouldReloadMatchSets = true;
    },

    /**
     * Handle the "next-match-set-created" event from the MarkerSetResultScorerControls component.
     *
     * @param {MatchSet} matchSet
     */
    onNextMatchSetCreated(matchSet: MatchSet): void {
      this.loadMatchState();

      this.matchSets.push(matchSet);

      this.selected = matchSet.id;
    },

    /**
     * Handle the "mark-match-completed" event from the MarkerResultScorerControl component.
     */
    onMatchCompleted(): void {
      this.$emit("completed");
    },

    /**
     * Handle the "set-removed" event from the MarkerSetResultScorerControls component.
     *
     * @param {MatchSet} removedMatchSet
     */
    onSetRemoved(removedMatchSet: MatchSet): void {
      // If the removed set is the current selected set, then try and select the previous set
      // as well as remove the removed set from the memory.
      if (this.selectedMatchSetID === removedMatchSet.id) {
        const index = this.matchSets.findIndex(
          (matchSet) => matchSet.id === removedMatchSet.id
        );

        if (index !== -1) {
          this.matchSets.splice(index, 1);
        }

        if (this.matchSets.length > 0) {
          this.selected = this.matchSets[this.matchSets.length - 1].id;
        } else {
          this.selected = MANAGE_MATCH_OPTION;
        }
      }

      this.loadMatchState();
    },

    /**
     * Handle "error" event from any child components and display the error message.
     *
     * @param {string} message
     */
    onError(message: string): void {
      this.errorMessage = message;
      this.showErrorDialog = true;
    },
  },
});
