









































































import EndScorer from "@/components/EndScorer/index.vue";
import Vue, { VNode, VueConstructor } from "vue";
import { EndModel } from "@memberpoint/ba-result-components";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import MatchResult from "@/Classes/MatchResult";
import { AxiosResponse } from "axios";
import EnterScoresAction from "../../../MatchActions/EnterScoresAction.vue";
import UndoLastEndDialog from "@/controllers/app/marker-matches/scorer/_internal/UndoLastEndDialog.vue";
import FinalizeByeAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/FinalizeByeAction.vue";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";
import ForfeitMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ForfeitMatchAction.vue";
import SetMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/SetMatchUnplayedAction.vue";
import StandardResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/StandardResultScorerControlsMixin";
import EndScoringMixin from "@/controllers/app/marker-matches/scorer/Mixins/EndScoringMixin";
import { VDivider } from "vuetify/lib";
import { MatchActionVNode } from "@/controllers/app/marker-matches/scorer/types";
import VNodeBridgeFunctional from "@/components/VNodeBridge/VNodeBridgeFunctional";

/**
 * Internal component for managing controls for standard result that is used
 * by markers.
 *
 * @internal
 */
export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof StandardResultScorerControlsMixin> &
    InstanceType<typeof EndScoringMixin>
>).extend({
  name: "MarkerResultScorerControls",

  components: {
    VNodeBridgeFunctional,
    FinalizeByeAction,
    UndoLastEndDialog,
    EndScorer,
  },

  mixins: [StandardResultScorerControlsMixin, EndScoringMixin],

  data() {
    return {
      submittingEnd: false,
      completingMatch: false,
      completingMatchError: null as string | null,
    };
  },

  computed: {
    /**
     * Returns the number of ends that can be added.
     *
     * @return {?number}
     */
    numberOfEndsLeft(): number | null {
      if (typeof this.scoringOptions.endsPerMatch === "number") {
        return this.scoringOptions.endsPerMatch > this.ends.length
          ? this.scoringOptions.endsPerMatch - this.ends.length
          : 0;
      }

      return null;
    },

    /**
     * Returns TRUE if we can enter end scores; otherwise FALSE.
     *
     * @return {boolean}
     */
    canEnterEndScores(): boolean {
      return (
        this.matchData.matchCapabilities.canEnterEndScore &&
        this.status === "pending" &&
        !this.isFinalized
      );
    },

    /**
     * Returns TRUE if we should display the actions; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayActions(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.matchActionNodeCount > 0;
    },

    /**
     * Returns the rendered match actions VNodes.
     *
     * @return {VNode[]}
     */
    renderedMatchActions(): VNode[] {
      return this._renderMatchActions();
    },

    /**
     * Returns the number of actual action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    matchActionNodeCount(): number {
      let count = 0;

      this.renderedMatchActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },
  },

  methods: {
    _renderMatchActions(): VNode[] {
      const actionNodes: VNode[] = [];

      const divider = this.$createElement(VDivider);

      if (this.canEnterEndScores) {
        actionNodes.push(
          this.createMatchActionVNode(CompleteMatchAction, {
            props: {
              match: this.matchData,
              loading: this.completingMatch,
              error: this.completingMatchError,
              competitorOneTotalShots: this.competitorOneTotalEndShots,
              competitorTwoTotalShots: this.competitorTwoTotalEndShots,
            },
            on: {
              submit: this.onSubmitCompleteMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.completingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canEnterResults) {
        actionNodes.push(
          this.createMatchActionVNode(EnterScoresAction, {
            props: {
              competitorOne: this.matchData.competitorOne,
              competitorTwo: this.matchData.competitorTwo,
              title: "Enter Final Scores",
              buttonLabel: "Enter end-of-game Scores",
              loading: this.enteringFinalScores,
              error: this.enterFinalScoresError,
            },
            on: {
              submit: this.onEnterFinalScores,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.enterFinalScoresError = null;
              },
            },
            scopedSlots: {
              description: () => {
                return [
                  "Entering end-of-game-scores will ",
                  this.$createElement(
                    "strong",
                    {
                      class: "error--text",
                    },
                    "wipe all end results"
                  ),
                  " and select the winner based on the values entered below.",
                ];
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsUnplayed) {
        actionNodes.push(
          this.createMatchActionVNode(SetMatchUnplayedAction, {
            props: {
              loading: this.markingMatchUnplayed,
              error: this.markingMatchUnplayedError,
            },
            on: {
              submit: this.onMarkMatchUnplayed,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.markingMatchUnplayedError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsForfeit) {
        actionNodes.push(
          this.createMatchActionVNode(ForfeitMatchAction, {
            props: {
              match: this.matchData,
              loading: this.forfeitingMatch,
              error: this.forfeitingMatchError,
            },
            on: {
              submit: this.onForfeitMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.forfeitingMatchError = null;
              },
            },
          })
        );
      }

      return actionNodes;
    },

    /**
     * Handles the "submit" event from the scorer component.
     *
     * @param {{competitorOneScore: number, competitorTwoScore}} scores
     */
    onEndScoreSubmit(scores: {
      competitorOneScore: number;
      competitorTwoScore: number;
    }): void {
      if (this.submittingEnd) {
        return;
      }

      if (!this.result) {
        return;
      }

      this.submittingEnd = true;

      const manager = new CompetitionScoringManager();

      const result: MatchResult = this.result;

      manager
        .createEndForOwner(result.handle, {
          endNumber: this.nextEndNumber,
          competitorOneScore: scores.competitorOneScore,
          competitorTwoScore: scores.competitorTwoScore,
        })
        .then((end: EndModel) => {
          this.ends.push(end);

          this.clearScorer();
        })
        .catch((response: AxiosResponse) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem submitting the end.";
          }

          this.$emit("error", message);
        })
        .finally(() => {
          this.submittingEnd = false;
        });
    },

    /**
     * Handle the "submit" from the CompleteMatchAction component.
     *
     * @param {Function} closeDialog function to close the dialog.
     */
    onSubmitCompleteMatch(closeDialog: () => void): void {
      if (this.completingMatch) {
        return;
      }

      this.completingMatch = true;
      this.completingMatchError = null;

      if (!this.scoringOptions.canFinalizeMatchByMarker) {
        closeDialog();
        this.$emit("mark-match-completed");
      } else {
        const manager = new CompetitionScoringManager();

        manager
          .markMatchAsCompleted(this.match.id)
          .then(() => {
            closeDialog();
            this.$emit("mark-match-completed");
          })
          .catch((response) => {
            let message = ApiResponseHelper.getErrorMessageFromResponse(
              response
            );

            if (message === null) {
              message =
                "There was an error attempting to mark the match as completed.";
            }

            this.completingMatchError = message;
          })
          .finally(() => {
            this.completingMatch = false;
          });
      }
    },

    /**
     * Handle the "submit" event from the UndoLastEndDialog component.
     */
    onSubmitUndoLastEnd(): void {
      this.$emit("undo-last-end-submitted", this.matchData);
    },

    /**
     * Handle the "update" from the FinalizeByeAction component.
     */
    onFinalizeBye(): void {
      this.$emit("finalize-bye");
    },
  },
});
