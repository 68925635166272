import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import { ScoringOptions } from "@/types";
import StandardResultManager from "@/services/StandardResultManager";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import MatchActionsMixin from "@/controllers/app/marker-matches/scorer/Mixins/MatchActionsMixin";
import MatchExtModel from "@/Classes/MatchExtModel";

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchDetailsMixin> &
    InstanceType<typeof MatchActionsMixin>
>).extend({
  mixins: [MatchDetailsMixin, MatchActionsMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,
  },

  data() {
    return {
      matchData: this.match,
      enteringFinalScores: false,
      enterFinalScoresError: null as string | null,
      forfeitingMatch: false,
      forfeitingMatchError: null as string | null,
      markingMatchUnplayed: false,
      markingMatchUnplayedError: null as string | null,
      showActions: false,
    };
  },

  computed: {
    /**
     * Returns the shots for competitor 1.
     *
     * @return {number}
     */
    competitorOneTotalShots(): number {
      if (!this.result) {
        return 0;
      }

      return this.result.competitorOneShots || 0;
    },

    /**
     * Returns the shots for competitor 2.
     *
     * @return {number}
     */
    competitorTwoTotalShots(): number {
      if (!this.result) {
        return 0;
      }

      return this.result.competitorTwoShots || 0;
    },

    /**
     * Returns TRUE if current user can finalize match; otherwise FALSE.
     *
     * @return {boolean}
     */
    canFinalize(): boolean {
      return this.match.matchCapabilities.canFinalize;
    },

    /**
     * Returns TRUE if can call the finalize BYE action; otherwise FALSE.
     *
     * @return {boolean}
     */
    canFinalizeBye(): boolean {
      return this.canFinalize && this.isBye && !this.isFinalized;
    },
  },

  methods: {
    /**
     * Close the match options dialog and optionally reload the match.
     *
     * @param silent {boolean} if TRUE then emit an event.
     */
    closeOptions(silent = false): void {
      this.showActions = false;

      if (!silent) {
        this.$emit("close");
      }
    },

    /**
     * Handles the "submit" event from the SetMatchUnplayedAction component.
     *
     * @param {string} reason
     * @param {Function} closeDialog
     */
    onMarkMatchUnplayed(reason: string, closeDialog: () => void): void {
      if (this.markingMatchUnplayed) {
        return;
      }

      this.markingMatchUnplayed = true;
      this.markingMatchUnplayedError = null;

      const manager = new CompetitionScoringManager();

      manager
        .markMatchAsUnPlayed(this.match.id, reason)
        .then(() => {
          closeDialog();

          this.closeOptions(false);
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message =
              "There was an error attempting to mark the match as UNPLAYED.";
          }

          this.markingMatchUnplayedError = message;
        })
        .finally(() => {
          this.markingMatchUnplayed = false;
        });
    },

    /**
     * Handles the "submit" event from the ForfeitMatchAction component.
     *
     * @param {string} forfeitingCompetitorId
     * @param {Function} closeDialog
     */
    onForfeitMatch(
      forfeitingCompetitorId: string,
      closeDialog: () => void
    ): void {
      if (this.forfeitingMatch) {
        return;
      }

      this.forfeitingMatch = true;
      this.forfeitingMatchError = null;

      const manager = new CompetitionScoringManager();

      manager
        .markMatchAsForfeit(this.match.id, forfeitingCompetitorId)
        .then(() => {
          closeDialog();

          this.closeOptions(false);
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem forfeiting the match.";
          }

          this.forfeitingMatchError = message;
        })
        .finally(() => {
          this.forfeitingMatch = false;
        });
    },

    /**
     * Handles the "submit" from the EnterScoresAction component.
     *
     * @param {{ competitorOneScore: number, competitorTwoScore: number}} scores
     */
    onEnterFinalScores(scores: {
      competitorOneScore: number;
      competitorTwoScore: number;
    }): void {
      if (this.enteringFinalScores) {
        return;
      }

      this.enteringFinalScores = true;
      this.enterFinalScoresError = null;

      const manager = new StandardResultManager();

      manager
        .enterFinalScores(
          this.matchData.id,
          scores.competitorOneScore || 0,
          scores.competitorTwoScore || 0,
          true
        )
        .then(() => {
          this.$emit("final-scores-entered");
        })
        .catch(() => {
          this.enterFinalScoresError =
            "There was a problem trying to enter final scores.";
        })
        .finally(() => {
          this.enteringFinalScores = false;
        });
    },
  },
});
