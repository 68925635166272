


















































import { mdiTrashCanOutline } from "@mdi/js";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import Vue from "vue";

export default Vue.extend({
  name: "UndoLastEndDialog",
  props: {
    ownerHandle: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      submitting: false,
      errorMsg: "",
      removeIcon: mdiTrashCanOutline,
    };
  },
  methods: {
    removeLastEnd() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;

      const manager = new CompetitionScoringManager();

      manager
        .removeEnd(this.ownerHandle)
        .then(() => {
          this.$emit("submit");

          this.dialog = false;
        })
        .catch((err) => {
          this.errorMsg = err.data.errors[0].message;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
});
