import Vue from "vue";
import { mdiClose } from "@mdi/js";

export default Vue.extend({
  props: {
    error: {
      type: String,
      required: false,
      default: null,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showDialog: false,
      closeIcon: mdiClose,
    };
  },

  methods: {
    /**
     * Close the dialog.
     *
     * @emits close {void}
     */
    closeDialog(): void {
      this.showDialog = false;

      this.$emit("close");
    },

    /**
     * Handle the "click" for the submit button.
     */
    submit(): void {
      this.$emit("submit", this.closeDialog);
    },
  },
});
