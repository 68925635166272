
































































import Vue, { VueConstructor } from "vue";
import ControlActionDialogMixin from "@/controllers/app/marker-matches/scorer/Mixins/ControlActionDialogMixin";
import { VForm } from "@/types/vuetify";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ControlActionDialogMixin>
>).extend({
  name: "SetMatchUnplayedAction",

  mixins: [ControlActionDialogMixin],

  data() {
    return {
      unPlayedReason: null as string | null,
    };
  },

  methods: {
    /**
     * Returns TRUE if the form passes validation; otherwise FALSE.
     *
     * @return {boolean}
     */
    validateForm(): boolean {
      if (!this.$refs.form) {
        return false;
      }

      const form = this.$refs.form as VForm;

      return form.validate();
    },

    /**
     * Handle the "submit" button event.
     */
    submit(): void {
      if (this.validateForm()) {
        this.$emit("submit", this.unPlayedReason, this.closeDialog);
      }
    },
  },
});
