




























































import Vue from "vue";
import MatchExtModel from "@/Classes/MatchExtModel";
import SetResultManager from "@/services/SetResultManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "RemoveSetAction",

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    setNumber: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: "Remove Set",
    },
  },

  data() {
    return {
      removing: false,
      removingError: null as string | null,
      showDialog: false,
    };
  },

  methods: {
    submit(): void {
      if (this.removing) {
        return;
      }

      this.removingError = null;
      this.removing = true;

      const manager = new SetResultManager();

      manager
        .removeSet(this.match.id, this.setNumber)
        .then((matchSet) => {
          this.$emit("set-removed", matchSet, this.match);

          this.showDialog = false;
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was an error attempting to remove the set.";
          }

          this.removingError = message;
        })
        .finally(() => {
          this.removing = false;
        });
    },

    /**
     * Handle the "click" event for the cancel button.
     */
    onCancel(): void {
      this.showDialog = false;
      this.removingError = null;
    },
  },
});
