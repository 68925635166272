import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import MatchActionsMixin from "@/controllers/app/marker-matches/scorer/Mixins/MatchActionsMixin";
import MatchExtModel from "@/Classes/MatchExtModel";
import { ScoringOptions } from "@/types";
import MatchSet from "@/Classes/MatchSet";
import MatchResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/MatchResultScorerControlsMixin";

interface SetsWinnerLoser {
  winner: string | null;
  loser: string | null;
  setsWon: number | null;
}

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchResultScorerControlsMixin> &
    InstanceType<typeof MatchActionsMixin>
>).extend({
  mixins: [MatchResultScorerControlsMixin, MatchActionsMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,

    matchSet: {
      type: MatchSet as PropType<MatchSet | null>,
      required: false,
      default: null,
    },

    totalSets: {
      type: Number,
      required: false,
      default: 0,
    },

    hasTieBreak: {
      type: Boolean,
      required: false,
      default: false,
    },

    competitor1SetsWon: {
      type: Number,
      required: false,
      default: null,
    },

    competitor2SetsWon: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    /**
     * Returns the max number of sets that is set in the result options
     * otherwise returns 0 for no max limit.
     *
     * @return {number}
     */
    maxNumberOfSets(): number {
      const options = this.match.resultOptions;

      return typeof options.maxNumberOfSets === "number" &&
        options.maxNumberOfSets > 1
        ? options.maxNumberOfSets
        : 0;
    },

    /**
     * Returns TRUE if we can have tie-break set; otherwise FALSE.
     *
     * @return {boolean}
     */
    canHaveTieBreakSet(): boolean {
      return (
        this.canStartNextSet &&
        this.totalSets >= 2 &&
        this.competitor1SetsWon === this.competitor2SetsWon
      );
    },

    /**
     * Returns TRUE if we can start the next set; otherwise FALSE.
     *
     * @return {boolean}
     */
    canStartNextSet(): boolean {
      if (this.hasTieBreak) {
        return false;
      }

      if (this.maxNumberOfSets > 0) {
        return this.totalSets < this.maxNumberOfSets;
      }

      return true;
    },

    setsWinnerLoser(): SetsWinnerLoser {
      const winnerLoser: SetsWinnerLoser = {
        winner: null,
        loser: null,
        setsWon: null,
      };

      if (this.competitor1SetsWon > this.competitor2SetsWon) {
        winnerLoser.winner = this.match.competitorOne.getName();
        winnerLoser.loser = this.match.competitorTwo.getName();
        winnerLoser.setsWon = this.competitor1SetsWon;
      } else if (this.competitor1SetsWon < this.competitor2SetsWon) {
        winnerLoser.winner = this.match.competitorTwo.getName();
        winnerLoser.loser = this.match.competitorOne.getName();
        winnerLoser.setsWon = this.competitor2SetsWon;
      }

      return winnerLoser;
    },
  },

  methods: {
    renderSetsWinnerLoserDescription() {
      if (this.setsWinnerLoser.winner) {
        const setsWon: string = this.setsWinnerLoser.setsWon + "";

        return this.$createElement("p", [
          "Marking this match as completed will declare ",
          this.$createElement("strong", this.setsWinnerLoser.winner),
          " the winner with ",
          this.$createElement("strong", setsWon),
          " sets won.",
        ]);
      }

      return this.$createElement("p", [
        "Marking this match as completed will declare this match as a ",
        this.$createElement("strong", "DRAW"),
      ]);
    },
  },
});
