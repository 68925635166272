

























































import Vue, { VNode, VNodeData, VueConstructor } from "vue";
import EnterScoresAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/EnterScoresAction.vue";
import { VDivider, VBtn } from "vuetify/lib";
import VNodeBridgeFunctional from "@/components/VNodeBridge/VNodeBridgeFunctional";
import SideShotResultScorerControlsMixin from "@/controllers/app/marker-matches/scorer/Mixins/SideShotResultScorerControlsMixin";
import MarkSideMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchActions/MarkSideMatchUnplayedAction.vue";
import ForfeitSideMatchAction from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchActions/ForfeitSideMatchAction.vue";
import ConfirmMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ConfirmMatchAction.vue";
import {
  CompetitorScoringManager,
  EndScoringState,
} from "@/services/CompetitorScoringManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";
import ForfeitMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/ForfeitMatchAction.vue";
import SetMatchUnplayedAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/SetMatchUnplayedAction.vue";
import { MatchActionVNode } from "@/controllers/app/marker-matches/scorer/types";
import CompetitorEndScorer from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/CompetitorEndScorer.vue";
import EndScoringMixin from "@/controllers/app/marker-matches/scorer/Mixins/EndScoringMixin";

/**
 * Internal component for managing controls for side shot result that is used
 * by competitors.
 *
 * @internal
 */
export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof SideShotResultScorerControlsMixin> &
    InstanceType<typeof EndScoringMixin>
>).extend({
  name: "CompetitorSideShotResultScorerControls",

  components: { CompetitorEndScorer, VNodeBridgeFunctional },

  mixins: [SideShotResultScorerControlsMixin, EndScoringMixin],

  props: {
    enableEndScoring: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showActions: false,
      confirmingMatch: false,
      confirmingMatchError: null as string | null,
      completingMatch: false,
      completingMatchError: null as string | null,
      enableEndScoringControls: this.enableEndScoring,
    };
  },

  computed: {
    /**
     * Returns TRUE if we should display the actions; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayActions(): boolean {
      if (this.isFinalized) {
        return false;
      }

      return this.matchActionNodeCount > 0;
    },

    /**
     * Returns the rendered action nodes.
     *
     * @return {VNode[]}
     */
    renderedActions(): VNode[] {
      if (this.sideMatch !== null) {
        return this._renderSideMatchActions();
      }

      return this._renderMatchActions();
    },

    /**
     * Returns the number of actual action nodes rendered and ignore nodes
     * that are not e.g. v-divider
     *
     * @return {number}
     */
    matchActionNodeCount(): number {
      let count = 0;

      this.renderedActions.forEach((node) => {
        if ((node as MatchActionVNode)._isMatchAction) {
          count++;
        }
      });

      return count;
    },

    /**
     * Returns TRUE if the current user can enter ends; otherwise FALSE.
     *
     * @return {boolean}
     */
    canEnterEnds(): boolean {
      return (
        this.sideMatch !== null &&
        this.matchData.matchCapabilities.canEnterEndScore &&
        !this.sideMatch.isFinalized
      );
    },

    /**
     * Returns TRUE if the current user can manage the side matches; otherwise FALSE.
     *
     * @return {boolean}
     */
    canManageSideMatches(): boolean {
      // A competitor can manage the side match if the side match has not been finalized
      // and that the competitor has the capability to enter results.
      return (
        this.matchData.matchCapabilities.canEnterResults &&
        this.sideMatch !== null &&
        !this.sideMatch.isFinalized
      );
    },
  },

  methods: {
    /**
     * Handle the "end-scores-completed" from the CompetitorEndScorer component.
     *
     * @param {EndScoringState} endScoringState
     */
    onEndScoresCompleted(endScoringState: EndScoringState): void {
      this.$emit("end-scores-completed", endScoringState);
    },

    /**
     * Handle the "ends-completed" event from the CompetitorEndScorer component.
     */
    onEndsCompleted(): void {
      this.$emit("ends-completed");
    },

    /**
     * Handle the "error" event from the CompetitorEndScorer component.
     *
     * @param {string} message
     */
    onError(message: string) {
      this.$emit("error", message);
    },

    /**
     * Handles the "submit" event from the CompleteMatchAction component.
     *
     * @param {Function} closeDialog
     */
    onCompleteMatch(closeDialog: () => void) {
      if (this.completingMatch) {
        return;
      }

      this.completingMatch = true;
      this.completingMatchError = null;

      const manager = new CompetitorScoringManager();

      manager
        .completeMatch(this.match.id)
        .then(() => {
          closeDialog();

          this.$emit("mark-match-completed");
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was an error attempting to complete the match.";
          }

          this.completingMatchError = message;
        })
        .finally(() => {
          this.completingMatch = false;
        });
    },

    /**
     * Handle the "submit" event from the ConfirmMatchAction component.
     *
     * @param {Function} closeDialog close the dialog handler.
     */
    onConfirmMatch(closeDialog: () => void): void {
      if (this.confirmingMatch) {
        return;
      }

      this.confirmingMatch = true;
      this.confirmingMatchError = null;

      const manager = new CompetitorScoringManager();

      manager
        .confirmMatch(this.match.id)
        .then(() => {
          closeDialog();

          this.$emit("mark-match-completed");
        })
        .catch((response) => {
          let message = ApiResponseHelper.getErrorMessageFromResponse(response);

          if (message === null) {
            message = "There was a problem confirming the match.";
          }

          this.confirmingMatchError = message;
        })
        .finally(() => {
          this.confirmingMatch = false;
        });
    },

    /**
     * Render the actions for the main match.
     *
     * @return {VNode[]}
     *
     * @private
     */
    _renderMatchActions(): VNode[] {
      const divider = this.$createElement(VDivider);

      const actionNodes: VNode[] = [];

      // Can only either confirm or finalize a match.
      if (this.canFinalize) {
        let data: VNodeData = {
          props: {
            match: this.matchData,
            competitorOneTotalShots: this.competitorOneTotalShots,
            competitorTwoTotalShots: this.competitorTwoTotalShots,
            error: this.completingMatchError,
            loading: this.completingMatch,
          },
          on: {
            submit: this.onCompleteMatch,
            close: () => {
              // When the dialog is closed we should clear any existing errors.
              this.completingMatchError = null;
            },
          },
        };

        if (this.match.isCurrentUserAwayCompetitor) {
          data.scopedSlots = {
            warning: () => {
              return "";
            },
          };
        }

        actionNodes.push(
          this.createMatchActionVNode(CompleteMatchAction, data),
          divider
        );
      } else if (this.matchData.matchCapabilities.canConfirmResults) {
        actionNodes.push(
          this.createMatchActionVNode(ConfirmMatchAction, {
            props: {
              match: this.matchData,
              competitorOneTotalShots: this.competitorOneTotalShots,
              competitorTwoTotalShots: this.competitorTwoTotalShots,
              error: this.confirmingMatchError,
              loading: this.confirmingMatch,
            },
            on: {
              submit: this.onConfirmMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.confirmingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsUnplayed) {
        actionNodes.push(
          this.createMatchActionVNode(SetMatchUnplayedAction, {
            props: {
              match: this.matchData,
              error: this.markingMatchUnplayedError,
              loading: this.markingMatchUnplayed,
            },
            on: {
              submit: this.onMarkMatchUnplayed,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.markingMatchUnplayedError = null;
              },
            },
          }),
          divider
        );
      }

      if (this.matchData.matchCapabilities.canSetAsForfeit) {
        actionNodes.push(
          this.createMatchActionVNode(ForfeitMatchAction, {
            props: {
              match: this.matchData,
              error: this.forfeitingMatchError,
              loading: this.forfeitingMatch,
            },
            on: {
              submit: this.onForfeitMatch,
              close: () => {
                // When the dialog is closed we should clear any existing errors.
                this.forfeitingMatchError = null;
              },
            },
          }),
          divider
        );
      }

      return actionNodes;
    },

    /**
     * Render the actions for the side match.
     *
     * @return {VNode[]}
     *
     * @private
     */
    _renderSideMatchActions(): VNode[] {
      const actionNodes: VNode[] = [];

      const divider = this.$createElement(VDivider);

      if (this.canEnterEnds) {
        actionNodes.push(
          this.createMatchActionVNode(
            VBtn,
            {
              props: {
                block: true,
                color: "primary",
                large: true,
                tile: true,
                text: true,
              },
              on: {
                click: () => {
                  this.enableEndScoringControls = true;
                  this.$emit("end-scoring-enabled");
                },
              },
            },
            ["Enable Scoring"]
          ),
          divider
        );
      }

      if (!this.canManageSideMatches) {
        return actionNodes;
      }

      actionNodes.push(
        this.createMatchActionVNode(EnterScoresAction, {
          props: {
            competitorOne: this.matchData.competitorOne,
            competitorTwo: this.matchData.competitorTwo,
            title: "Enter Final Scores for Rink",
            buttonLabel: "Enter end-of-game Scores",
            error: this.enteringFinalScoresForSideMatchError,
            loading: this.enteringFinalScoresForSideMatch,
          },
          on: {
            submit: this.onEnterFinalScoresForSideMatch,
            close: () => {
              // When the dialog is closed we should clear any existing errors.
              this.enteringFinalScoresForSideMatchError = null;
            },
          },
          scopedSlots: {
            description: () => {
              return [
                "Entering end-of-game-scores will ",
                this.$createElement(
                  "strong",
                  {
                    class: "error--text",
                  },
                  "wipe all end results"
                ),
                " and select the winner based on the values entered below.",
              ];
            },
          },
        }),
        divider
      );

      actionNodes.push(
        this.createMatchActionVNode(MarkSideMatchUnplayedAction, {
          props: {
            error: this.markingSideMatchUnplayedError,
            loading: this.markingSideMatchUnplayed,
          },
          on: {
            submit: this.onMarkSideMatchUnplayed,
            close: () => {
              // When the dialog is closed we should clear any existing errors.
              this.markingSideMatchUnplayedError = null;
            },
          },
        }),
        divider
      );

      actionNodes.push(
        this.createMatchActionVNode(ForfeitSideMatchAction, {
          props: {
            match: this.match,
            error: this.forfeitingSideMatchError,
            loading: this.forfeitingSideMatch,
          },
          on: {
            submit: this.onForfeitSideMatch,
            close: () => {
              // When the dialog is closed we should clear any existing errors.
              this.forfeitingSideMatchError = null;
            },
          },
        }),
        divider
      );

      return actionNodes;
    },
  },
});
