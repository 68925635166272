
















































































import CompleteMatchAction from "@/controllers/app/marker-matches/scorer/_internal/MatchActions/CompleteMatchAction.vue";

export default CompleteMatchAction.extend({
  name: "CompleteSideMatchAction",
});
