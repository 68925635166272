import { render, staticRenderFns } from "./CompetitorSideShotResultScorerControls.vue?vue&type=template&id=51aca260&scoped=true&"
import script from "./CompetitorSideShotResultScorerControls.vue?vue&type=script&lang=ts&"
export * from "./CompetitorSideShotResultScorerControls.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51aca260",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomNavigation,VBottomSheet,VBtn,VSheet})
